import React from "react";
import clsx from "clsx";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "components/Box";
import Button from "components/Button";
import CircularProgress from "components/Progress/CircularProgress";
import TextField from "components/TextField";

interface ISearchInputProps {
  appearance?: "" | "add";
  disableAdd?: boolean;
  placeholder?: string;
  renderInputParams?: any;
  loading?: boolean;
  inputValue?: any;
  onClickCancel?: any;
  onAddClick?: () => void;
  testId?: string;
  ariaLabel?: string;
  isSlotActionDrawer?: boolean;
}

const useRootInputStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (isSlotActionDrawer) => ({
      border: isSlotActionDrawer
        ? `1px solid ${theme.palette?.text?.mediumDarkGray}`
        : `1px solid ${theme.palette?.strokes?.divider}`,
      overflow: "hidden",
      borderRadius: 10,
      height: 42,
      marginTop: isSlotActionDrawer ? "8px" : "0px",
      paddingLeft: 8,
      paddingRight: isSlotActionDrawer ? "0px !important" : "32px !important",
      color: isSlotActionDrawer ? theme.palette?.text?.primary : "inherit",
      backgroundColor: isSlotActionDrawer
        ? "#FFFFFF"
        : theme.palette?.strokes?.divider,
      "&.Mui-focused": {
        borderColor: theme.palette.secondary.main,
      },
      "& .MuiAutocomplete-clearIndicator": {
        color: theme.palette?.strokes?.divider,
      },
      "& .MuiSvgIcon-root": {
        color: isSlotActionDrawer
          ? theme.palette?.text?.secondary
          : "rgba(43, 57, 73, 0.7)",
      },
      "& .MuiAutocomplete-endAdornment": {
        top: "3px",
        right: isSlotActionDrawer ? "22px !important" : "9px",
      },
    }),
    focused: {},
  })
);

const useInputStyles = makeStyles(() =>
  createStyles({
    searchFieldWithButton: {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },
    searchButton: {
      height: 42,
      borderRadius: 10,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
    },
  })
);

const SearchInput = ({
  appearance,
  disableAdd,
  placeholder,
  renderInputParams,
  loading,
  inputValue,
  onClickCancel,
  onAddClick,
  ariaLabel,
  testId = "search-input",
  isSlotActionDrawer,
}: ISearchInputProps) => {
  const rootClasses = useRootInputStyles(isSlotActionDrawer);
  const classes = useInputStyles();
  //mui has a bug that causes InputProps to be passed to child div of TextField, not <input> element

  return (
    <Box display="flex" alignItems="center">
      <TextField
        {...renderInputParams}
        data-testid={testId}
        placeholder={placeholder}
        InputProps={{
          ...renderInputParams?.InputProps,
          "aria-label": ariaLabel || placeholder,
          role: "search",
          classes: rootClasses,
          className: clsx({
            [classes.searchFieldWithButton]: appearance === "add",
          }),
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start" style={{ marginTop: 2 }}>
              {!isSlotActionDrawer && <SearchIcon />}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {renderInputParams?.InputProps?.endAdornment}
              {isSlotActionDrawer && (
                <SearchIcon style={{ marginRight: "8px" }} />
              )}
              {loading ? (
                <CircularProgress
                  style={{
                    marginRight: 8,
                  }}
                  color="inherit"
                  size={20}
                />
              ) : null}
              {/* TODO: remove? doesn't appear to show cancel icon 
              CancelIcon rendered in Autocomplete in SearchField */}
              {inputValue && (
                <CancelIcon
                  style={{
                    marginRight: "8px",
                  }}
                  onClick={() => {
                    onClickCancel();
                  }}
                />
              )}
            </InputAdornment>
          ),
        }}
      />
      {appearance && appearance === "add" && (
        <Button
          data-testid="search-and-add-button"
          data-e2e="search-and-add-button"
          disabled={disableAdd}
          className={classes.searchButton}
          color="primary"
          onClick={() => {
            // @ts-ignore: Object is possibly 'null'.
            onAddClick();
          }}
        >
          Add
        </Button>
      )}
    </Box>
  );
};

export default SearchInput;
