import { useQuery } from "@tanstack/react-query";
import { FetchConsentsRequest, fetchConsentApi } from "shared/fetch/src/apis/ConsentApi";

export const memberConsentsQueryKey = (
  memberId?: string | number,
  page?: string
) =>
  ["memberConsents", memberId?.toString(), page?.toString()].filter(
    (k) => typeof k === "string"
  );

const useGetConsents = (request: FetchConsentsRequest) => {
  const query = useQuery(
    memberConsentsQueryKey(request.memberId, request.page),
    () => fetchConsentApi.fetchConsents(request),
    {
      enabled: !!request.memberId && request.memberId !== undefined,
      keepPreviousData: true,
      staleTime: 10000,
    }
  );
  return query;
};

export default useGetConsents;
