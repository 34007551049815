import { useQuery } from "@tanstack/react-query";
import {
  GetMemberRequiredConsentsRequest,
  fetchConsentApi,
} from "shared/fetch/src/apis/ConsentApi";

export const memberRequiredConsentsQueryKey = (
  requestParameters: GetMemberRequiredConsentsRequest
) => ["memberRequiredConsents", requestParameters];

const useGetMemberRequiredConsents = (
  requestParameters: GetMemberRequiredConsentsRequest
) => {
  const { data } = useQuery(
    memberRequiredConsentsQueryKey(requestParameters),
    () => {
      return fetchConsentApi.getMemberRequiredConsents(requestParameters);
    },
    {
      enabled: Boolean(requestParameters.appointmentType),
    }
  );
  return { consents: data?.results };
};

export default useGetMemberRequiredConsents;
