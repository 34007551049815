import { useQuery } from "@tanstack/react-query";
import { fetchEpisodeListsApi } from "shared/fetch/src/apis/EpisodeListsApi";
import { getQueryString } from "shared/state/queue";

export interface IRequest {
  filterNames: string[];
  values: any[];
  filter: string;
  order?: any;
}

export const fetchYourFilteredEpisodesQueryKey = (request: IRequest) => [
  "fetchYourQueue",
  request.filter,
  "value",
  request.values,
  "order",
  request.order,
];

export default (request: IRequest) => {
  const { filterNames, values, order } = request;
  const { data: yourFilteredQueueEpisodes, isLoading } = useQuery(
    fetchYourFilteredEpisodesQueryKey(request),
    () =>
      fetchEpisodeListsApi.getMyQueue({
        ...getQueryString(filterNames, values, order),
      }),
    {
      keepPreviousData: true,
      refetchInterval: 10000,
      enabled: !!request.filter,
    }
  );

  return {
    yourFilteredQueueEpisodes,
    isLoading,
  };
};
