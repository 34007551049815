// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    XOCalProviderAppointment,
    XOCalProviderAppointmentFromJSON,
    XOCalProviderAppointmentToJSON,
} from './XOCalProviderAppointment';


/**
 * 
 * @export
 * @interface XOCalProvider
 */
export interface XOCalProvider  {
    /**
     * 
     * @type {string}
     * @memberof XOCalProvider
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof XOCalProvider
     */
    flat?: string;
    /**
     * 
     * @type {string}
     * @memberof XOCalProvider
     */
    type?: XOCalProviderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof XOCalProvider
     */
    serviceType?: string;
    /**
     * 
     * @type {string}
     * @memberof XOCalProvider
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof XOCalProvider
     */
    globalId?: string;
    /**
     * 
     * @type {string}
     * @memberof XOCalProvider
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof XOCalProvider
     */
    guild?: string;
    /**
     * 
     * @type {string}
     * @memberof XOCalProvider
     */
    bioUrl?: string;
    /**
     * 
     * @type {Array<XOCalProviderAppointment>}
     * @memberof XOCalProvider
     */
    appointmentsByModality?: Array<XOCalProviderAppointment>;
}

export function XOCalProviderFromJSON(json: any): XOCalProvider {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'flat': !exists(json, 'flat') ? undefined : json['flat'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'serviceType': !exists(json, 'service_type') ? undefined : json['service_type'],
        'providerId': !exists(json, 'provider_id') ? undefined : json['provider_id'],
        'globalId': !exists(json, 'global_id') ? undefined : json['global_id'],
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'guild': !exists(json, 'guild') ? undefined : json['guild'],
        'bioUrl': !exists(json, 'bio_url') ? undefined : json['bio_url'],
        'appointmentsByModality': !exists(json, 'appointments_by_modality') ? undefined : (json['appointments_by_modality'] as Array<any>).map(XOCalProviderAppointmentFromJSON),
    };
}

export function XOCalProviderToJSON(value?: XOCalProvider): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'flat': value.flat,
        'type': value.type,
        'service_type': value.serviceType,
        'provider_id': value.providerId,
        'global_id': value.globalId,
        'avatar_url': value.avatarUrl,
        'guild': value.guild,
        'bio_url': value.bioUrl,
        'appointments_by_modality': value.appointmentsByModality === undefined ? undefined : (value.appointmentsByModality as Array<any>).map(XOCalProviderAppointmentToJSON),
    };
}

/**
* @export
* @enum {string}
*/
export enum XOCalProviderTypeEnum {
    Physician = 'Physician',
    NursePractitioner = 'Nurse Practitioner',
    Nurse = 'Nurse',
    Optometrist = 'Optometrist',
    Chiropractor = 'Chiropractor',
    Acupuncturist = 'Acupuncturist',
    PhysicalTherapist = 'Physical Therapist',
    Therapist = 'Therapist',
    MentalHealth = 'Mental Health',
    HealthCoach = 'Health Coach',
    Dietitian = 'Dietitian',
    Optician = 'Optician',
    Staff = 'Staff'
}


