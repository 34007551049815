import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { ClearHeadsUpNotificationsRequest, fetchHeadsUpApi } from "shared/fetch/src/apis/HeadsUpApi";
import { showSnackbar } from "shared/state/ui/snackbar";

export default () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  let id: string;

  const {
    isSuccess: hasFinishedClearing,
    mutate: clearAllHeadsUpNotifications,
  } = useMutation(
    (ctmId: ClearHeadsUpNotificationsRequest) => {
      id = ctmId.id;
      return fetchHeadsUpApi.clearHeadsUpNotifications(ctmId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["headsUpNotifications", id],
        });
        dispatch(
          showSnackbar("Success! All Heads Up notifications are cleared.")
        );
      },
      onError: () => {
        dispatch(
          showSnackbar("There was a problem clearing notifications.", "danger")
        );
      },
    }
  );

  return {
    hasFinishedClearing,
    clearAllHeadsUpNotifications,
  };
};
