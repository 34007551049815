import { useQuery } from "@tanstack/react-query";
import {
  GetDependentsRequest,
  GetDependentsShowEnum,
  GetDependentsStatusEnum,
  fetchDependentsApi,
} from "shared/fetch/src/apis/DependentsApi";

export const dependentsQueryKey = (
  id?: string | number,
  show?: GetDependentsShowEnum,
  status?: GetDependentsStatusEnum
) => ["dependents", id?.toString(), show, status];

const useGetAdultDependents = (request: GetDependentsRequest) => {
  const { data, isInitialLoading } = useQuery(
    dependentsQueryKey(request.id, request.show, request.status),
    () => fetchDependentsApi.getDependents(request),
    {
      enabled: !!request.id,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    }
  );
  return { adultDependents: data, isLoading: isInitialLoading };
};

export default useGetAdultDependents;
