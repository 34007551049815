import {
  FetchComponentRequest,
  fetchComponentsApi,
} from "shared/fetch/src/apis/ComponentsApi";
import { useQuery } from "@tanstack/react-query";

export const fetchComponentDetailsQueryKey = (
  request: FetchComponentRequest
) => ["component-details", request.id];

const useGetComponentDetails = (request: FetchComponentRequest) => {
  const {
    data: component,
    isInitialLoading,
    refetch,
  } = useQuery(
    fetchComponentDetailsQueryKey(request),
    () => {
      return fetchComponentsApi.fetchComponent(request);
    },
    {
      enabled: !!request.id,
    }
  );

  return {
    refresh: refetch,
    component,
    isPending: isInitialLoading,
  };
};

export default useGetComponentDetails;
