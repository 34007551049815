// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeatureFlagSchemaResults
 */
export interface FeatureFlagSchemaResults  {
    /**
     * 
     * @type {string}
     * @memberof FeatureFlagSchemaResults
     */
    clientName?: string;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof FeatureFlagSchemaResults
     */
    enabledEfIds?: Array<Array<string>>;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof FeatureFlagSchemaResults
     */
    disabledEfIds?: Array<Array<string>>;
}

export function FeatureFlagSchemaResultsFromJSON(json: any): FeatureFlagSchemaResults {
    return {
        'clientName': !exists(json, 'client_name') ? undefined : json['client_name'],
        'enabledEfIds': !exists(json, 'enabled_ef_ids') ? undefined : json['enabled_ef_ids'],
        'disabledEfIds': !exists(json, 'disabled_ef_ids') ? undefined : json['disabled_ef_ids'],
    };
}

export function FeatureFlagSchemaResultsToJSON(value?: FeatureFlagSchemaResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'client_name': value.clientName,
        'enabled_ef_ids': value.enabledEfIds,
        'disabled_ef_ids': value.disabledEfIds,
    };
}


