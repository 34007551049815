import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Button from "components/Button";
import Typography from "components/Typography";
import TextField from "components/TextField";
import classes from "./index.module.css";
import useCreateVisitNotes from "shared/features/visitNotes/useCreateVisitNotes";
import { Delete } from "@mui/icons-material";
import useDeleteVisitNote from "shared/features/visitNotes/useDeleteVisitNote";
import { getFetchSlotQueryKey } from "shared/features/xocal/useGetSlot";
import { getFetchSlotsQueryKey } from "shared/features/xocal/useGetSlots";
import { useQueryClient } from "@tanstack/react-query";
import { AppointmentOutput } from "shared/fetch/src/models/AppointmentOutput";
import { Appointment } from "shared/fetch/src/models/Appointment";
import { showSnackbar } from "shared/state/ui/snackbar";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUser } from "shared/features/user";
import DeleteNoteConfirmationModal from "./DeleteNoteConfirmationModal";
import useFormatDate from "shared/utils/useFormatDate";
import ReadMore from "components/ReadMore";

const isXOCalAppointmentType = (
  appointment: AppointmentOutput | Appointment
) => {
  if ((appointment as AppointmentOutput).patientId) {
    return true;
  }
  return false;
};
interface Props {
  open: boolean;
  onClose: () => void;
  appointmentType?: string;
  appointment: AppointmentOutput | Appointment;
  patientName?: string;
}

const VisitNotesModal = ({
  open,
  onClose,
  appointment,
  appointmentType,
  patientName,
}: Props) => {
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [note, setNote] = useState("");
  const [
    isDeleteNoteConfirmationModalOpen,
    setIsDeleteNoteConfirmationModalOpen,
  ] = useState(false);
  const [deletingNoteId, setDeletingNoteId] = useState<number | null>(null);
  const queryClient = useQueryClient();
  const isXOCal = isXOCalAppointmentType(appointment);
  const patientId = (appointment as Appointment)?.patient?.id;
  const dispatch = useDispatch();
  const currentUser = useSelector(getUser);
  const formatDate = useFormatDate();

  const { mutateAsync: createVisitNote, isLoading } =
    useCreateVisitNotes(isXOCal);

  const { mutateAsync: deleteVisitNote, isLoading: isDeleteNoteLoading } =
    useDeleteVisitNote({
      onSuccess: () => {
        dispatch(showSnackbar("Success! The visit note has been deleted."));
        if (isXOCal) {
          queryClient.invalidateQueries(
            getFetchSlotQueryKey({ id: appointment?.slotId?.toString() })
          );
          queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
        } else {
          queryClient.invalidateQueries(["visits", patientId?.toString()]);
        }
        setIsDeleteNoteConfirmationModalOpen(false);
      },
    });

  const handleSaveNote = async () => {
    await createVisitNote({
      id: appointment?.id.toString() || "",
      createAppointmentNotes: {
        note,
      },
    }).then(() => {
      dispatch(showSnackbar("Success! You added a visit note."));
      setIsAddingNote(false);
      setNote("");
    });
    setIsAddingNote(false);
    setNote("");
  };

  const handleDeleteNoteClick = async () => {
    await deleteVisitNote({
      id: deletingNoteId?.toString()!,
    });
  };

  const handleClose = () => {
    setIsAddingNote(false);
    setNote("");
    setIsDeleteNoteConfirmationModalOpen(false);
    onClose();
  };

  return (
    <>
      <DeleteNoteConfirmationModal
        isDeleteNoteConfirmationModalOpen={isDeleteNoteConfirmationModalOpen}
        setIsDeleteNoteConfirmationModalOpen={
          setIsDeleteNoteConfirmationModalOpen
        }
        handleDeleteNoteClick={handleDeleteNoteClick}
        isDeleteNoteLoading={isDeleteNoteLoading}
      />
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <div data-testid="visit-notes-modal" style={{ height: 395 }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={() => ({
              position: "absolute",
              right: 12,
              top: 8,
            })}
          >
            <CloseIcon />
          </IconButton>

          <DialogTitle className={classes.visitNotesModalTitle}>
            Visit notes
          </DialogTitle>
          <DialogContent className={classes.visitNotesModalContent}>
            <div className={classes.memberInformation}>
              {!patientName && (
                <Typography variant="h6" className={classes.memberName}>
                  {isXOCal
                    ? (appointment as AppointmentOutput).patientInfo?.name
                    : (appointment as Appointment).patient?.name}
                </Typography>
              )}
              {patientName && (
                <Typography variant="h6" className={classes.memberName}>
                  {patientName}
                </Typography>
              )}
              <Typography variant="body1" className={classes.memberCaption}>
                {appointmentType}
              </Typography>
            </div>
            {isAddingNote ? (
              <div className={classes.addNoteContainer}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold" }}
                  onClick={() => setIsAddingNote(true)}
                >
                  Add note
                </Typography>
                <TextField
                  placeholder="Write a visit note..."
                  multiline
                  data-testid="visit-note-textarea"
                  className={classes.textArea}
                  rows={4}
                  fullWidth
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  margin="normal"
                  aria-label="Add a note"
                  inputProps={{
                    "aria-label": "Add a note",
                  }}
                />
                <div>
                  <Button
                    color="primary"
                    size="small"
                    disabled={!note}
                    isLoading={isLoading}
                    onClick={handleSaveNote}
                  >
                    Save
                  </Button>
                  <Button
                    color="link-primary"
                    size="small"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={classes.tableHeader}
                      style={{ width: "91px", paddingRight: "11px" }}
                    >
                      DATE
                    </TableCell>
                    <TableCell
                      className={classes.tableHeader}
                      style={{ width: "240px" }}
                    >
                      NOTE
                    </TableCell>
                    <TableCell
                      className={classes.tableHeader}
                      style={{
                        width: "128px",
                        paddingRight: "12px",
                        paddingLeft: "12px",
                      }}
                    >
                      AUTHOR
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.tableHeaderRight}
                      style={{ width: "69px" }}
                    >
                      <Button
                        color="link-primary"
                        size="small"
                        onClick={() => setIsAddingNote(true)}
                        aria-label="Add note"
                      >
                        Add note
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {appointment.appointmentNotes?.map((appointmentNote: any) => (
                    <TableRow
                      key={appointmentNote.createdAt?.getMilliseconds()}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                      className={classes.tableBodyRow}
                    >
                      <TableCell
                        className={classes.tableBody}
                        style={{ paddingRight: "11px" }}
                      >
                        {formatDate(appointmentNote.createdAt!, "MM/dd/yy")}
                        <br />
                        {formatDate(appointmentNote.createdAt!, "hh:mm a z")}
                      </TableCell>
                      <TableCell className={classes.tableBody}>
                        <Typography
                          data-testid="visit-note-text"
                          className={classes.note}
                        >
                          <ReadMore
                            maxLength={97}
                            buttonClassName={classes.readMoreButton}
                            addEllipsis
                          >
                            {appointmentNote.note}
                          </ReadMore>
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={classes.tableBody}
                        style={{ paddingRight: "12px", paddingLeft: "12px" }}
                      >
                        <Typography
                          variant="body2"
                          className={classes.authorName}
                        >
                          {appointmentNote.authorNameAndTitle}
                        </Typography>

                        {appointmentNote.noteType ? (
                          <span className={classes.systemNoteChip}>
                            SYSTEM NOTE
                          </span>
                        ) : (
                          <span className={classes.manualNoteChip}>
                            MANUAL NOTE
                          </span>
                        )}
                      </TableCell>
                      <TableCell align="right" className={classes.tableBody}>
                        {!appointmentNote.noteType &&
                          appointmentNote.createdBy === currentUser?.id && (
                            <Button
                              aria-label="Delete visit note"
                              color="inherit"
                              size="small"
                              sx={{ minWidth: "0px", padding: "0px" }}
                              onClick={() => {
                                setDeletingNoteId(appointmentNote.id!);
                                setIsDeleteNoteConfirmationModalOpen(true);
                              }}
                              data-testid="delete-visit-note"
                              className={classes.deleteButton}
                            >
                              <Delete />
                            </Button>
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default VisitNotesModal;
