import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export type sizes = "xs" | "sm" | "md" | "lg" | "xl";

interface IMatches {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  xsDown: boolean;
  smDown: boolean;
  mdDown: boolean;
  lgDown: boolean;
  xlDown: boolean;
  xsUp?: boolean;
  smUp?: boolean;
  mdUp?: boolean;
  lgUp?: boolean;
  xlUp?: boolean;
  tabletDown?: boolean;
}

export const useBreakpointMatch = () => {
  const matches: IMatches = {
    xs: useMediaQuery((theme: Theme) => theme.breakpoints.up("xs")),
    sm: useMediaQuery((theme: Theme) => theme.breakpoints.up("sm")),
    md: useMediaQuery((theme: Theme) => theme.breakpoints.up("md")),
    lg: useMediaQuery((theme: Theme) => theme.breakpoints.up("lg")),
    xl: useMediaQuery((theme: Theme) => theme.breakpoints.up("xl")),
    xsDown: useMediaQuery((theme: Theme) => theme.breakpoints.down("sm")),
    smDown: useMediaQuery((theme: Theme) => theme.breakpoints.down("md")),
    mdDown: useMediaQuery((theme: Theme) => theme.breakpoints.down("lg")),
    lgDown: useMediaQuery((theme: Theme) => theme.breakpoints.down("xl")),
    xlDown: useMediaQuery((theme: Theme) => theme.breakpoints.down("xl")),
    tabletDown: useMediaQuery("(max-width:992px)"),
  };

  matches.xsUp = matches.xs;
  matches.smUp = matches.sm;
  matches.mdUp = matches.md;
  matches.lgUp = matches.lg;
  matches.xlUp = matches.xl;

  const onBreakpointMatch = (obj: any, callback: any) => {
    if (obj && typeof obj === "object") {
      Object.keys(matches).forEach((k) => {
        const foundKey = Object.keys(obj).find((x) => x === k);
        if (foundKey && matches[foundKey]) {
          callback(obj[foundKey]);
        }
      });
    }
  };

  return {
    matches,
    onBreakpointMatch,
    hasMatch: (breakpoints: sizes[] | sizes, direction: "up" | "down") => {
      let match = false;
      const breakpointItems = Array.isArray(breakpoints)
        ? breakpoints
        : [breakpoints];
      breakpointItems.forEach((breakpoint) => {
        if (direction === "up" && matches[`${breakpoint}Up`]) {
          match = true;
        } else if (direction === "down" && matches[`${breakpoint}Down`]) {
          match = true;
        }
      });
      return match;
    },
  };
};
