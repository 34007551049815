import { useQuery } from "@tanstack/react-query";
import {
  GetUserSettingsRequest,
  fetchUserSettingsApi,
} from "shared/fetch/src/apis/UserSettingsApi";

export const getUserSettingsQueryKey = (request: GetUserSettingsRequest) => [
  "userSettings",
  Object.values(request),
];

interface FilterSection {
  ctm?: { id: string; podId?: string }[];
}
interface UserSettings {
  filters?: { [key: string]: FilterSection | undefined };
  isCtmReplyBoxDockedRight?: boolean;
  [key: string]: any;
}

const useGetUserSettings = (request: GetUserSettingsRequest) => {
  const {
    data: userSettings,
    refetch: refetchUserSettings,
    ...query
  } = useQuery(
    getUserSettingsQueryKey(request),
    async () => {
      const { data } =
        (await fetchUserSettingsApi.getUserSettings(request)) || {};
      const settings = data ? JSON.parse(data) : {};
      ["queue", "episodes", "proactive"].forEach((section) => {
        if (
          typeof settings?.filters?.[section]?.ctm?.[0]?.podId ===
            "undefined" &&
          settings?.filters?.[section]
        ) {
          settings.filters[section].ctm = [];
        }
      });

      return settings as UserSettings;
    },
    { enabled: !!request.id }
  );

  return { userSettings, refetchUserSettings, ...query };
};

export default useGetUserSettings;
