import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";
import {
  UpdateVirtualCareTypeRequest,
  fetchEpisodesOfCareApi,
} from "shared/fetch/src/apis/EpisodesOfCareApi";
import { UpdateVirtualCareTypeRequestBodyVucEventEnum } from "shared/fetch/src/models/UpdateVirtualCareTypeRequestBody";
import { episodeQueryKey } from "./useFetchEpisode";

const useUpdateVirtualCareTypeMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const updateVirtualCareTypeMutation = useMutation(
    (request: UpdateVirtualCareTypeRequest) =>
      fetchEpisodesOfCareApi.updateVirtualCareType(request),
    {
      onSuccess: (_response, request) => {
        const { updateVirtualCareTypeRequestBody } = request;
        const snackbarSuccessText = `Success! This conversation was ${
          updateVirtualCareTypeRequestBody?.vucEvent ===
          UpdateVirtualCareTypeRequestBodyVucEventEnum.Marked
            ? "marked"
            : "unmarked"
        } as VUC.`;
        dispatch(showSnackbar(snackbarSuccessText, "success"));
        queryClient.invalidateQueries(episodeQueryKey(request.id));
      },
      onError: (_response, request) => {
        const { updateVirtualCareTypeRequestBody } = request;
        const snackbarErrorText = `Error ${
          updateVirtualCareTypeRequestBody?.vucEvent ===
          UpdateVirtualCareTypeRequestBodyVucEventEnum.Marked
            ? "marking"
            : "unmarking"
        } this conversation as VUC. Please try again.`;
        dispatch(showSnackbar(snackbarErrorText, "danger"));
      },
    }
  );
  return updateVirtualCareTypeMutation;
};

export default useUpdateVirtualCareTypeMutation;
