
import { useQuery } from "@tanstack/react-query";
import { FetchCTMHeadsUpNotificationTypesRequest, fetchHeadsUpApi } from "shared/fetch/src/apis/HeadsUpApi";

export const headsUpNotificationsTypesQueryKey = (id: string | number) => [
  "headsUpNotificationsTypes",
  id.toString(),
];

export default (request: FetchCTMHeadsUpNotificationTypesRequest) => {
  const {
    isInitialLoading: isLoading,
    isRefetching,
    data: headsUpNotificationsTypes,
  } = useQuery(
    headsUpNotificationsTypesQueryKey(request.userId),
    () => fetchHeadsUpApi.fetchCTMHeadsUpNotificationTypes(request),
    {
      enabled: !!request.userId,
    }
  );

  return {
    isLoading,
    isRefetching,
    headsUpNotificationsTypes,
  };
};
