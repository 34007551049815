import { useQuery } from "@tanstack/react-query";
import { FetchUserPaginatedDocumentsRequest, fetchDocumentsApi } from "shared/fetch/src/apis/DocumentsApi";

export const paginatedDocumentsByUserIdQueryKey = (
  id?: string | number,
  page?: string
) => ["paginatedDocumentsByUserId", id?.toString(), page];

const useGetDocuments = (request: FetchUserPaginatedDocumentsRequest) => {
  const query = useQuery(
    paginatedDocumentsByUserIdQueryKey(request.id, request.page),
    () => fetchDocumentsApi.fetchUserPaginatedDocuments(request),
    {
      enabled: !!request.id && request.id !== undefined,
      keepPreviousData: true,
    }
  );
  return query;
};

export default useGetDocuments;
