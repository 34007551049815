import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { fetchSlotsApi } from "shared/fetch/src/apis/SlotsApi";

interface GetSlotByIDRequest {
  id?: string;
}

export const getFetchSlotQueryKey = (requestParameters: GetSlotByIDRequest) => {
  return ["slot", ...Object.entries(requestParameters)];
};

type Slot = ReturnType<typeof fetchSlotsApi.getSlotByID> extends Promise<
  infer R
>
  ? R
  : never;
type TQueryKey = ReturnType<typeof getFetchSlotQueryKey>;

const useGetSlot = (
  { id }: GetSlotByIDRequest,
  options?: Omit<
    UseQueryOptions<Slot, Error, Slot, TQueryKey>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery(
    getFetchSlotQueryKey({ id }),
    () => fetchSlotsApi.getSlotByID({ id: id! }),
    {
      enabled: !!id,
      ...options,
    }
  );
};

export default useGetSlot;
