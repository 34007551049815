import {
  fetchComponentsApi,
  AcknowledgeComponentsRequest,
} from "shared/fetch/src/apis/ComponentsApi";
import { useMutation } from "@tanstack/react-query";

const useAcknowledgeComponents = () => {
  const mutation = useMutation(
    (requestParameters: AcknowledgeComponentsRequest) => {
      return fetchComponentsApi.acknowledgeComponents(requestParameters);
    }
  );

  return { acknowledgeComponents: mutation.mutate };
};

export default useAcknowledgeComponents;
