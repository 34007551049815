const SET_CLINIC = "SET_CLINIC";
const SET_IS_SIDEBAR_VISIBLE = "SET_IS_SIDEBAR_VISIBLE";

export const defaultStore = {
  clinic: null,
  isSidebarVisible: null,
};

export const generateDefaultScheduleTab = () => {
  return defaultStore;
};

export const getInitialScheduleTabState = (): IState => {
  return {
    clinic: null,
    isSidebarVisible: null,
  };
};

export interface IState {
  clinic: string | null;
  isSidebarVisible: boolean | null;
}

export const scheduleTab = (
  state: IState = generateDefaultScheduleTab(),
  action: any
): IState => {
  switch (action.type) {
    case SET_CLINIC:
      return {
        ...state,
        clinic: action.payload,
      };
    case SET_IS_SIDEBAR_VISIBLE:
      return {
        ...state,
        isSidebarVisible: action.payload,
      };
    default:
      return state;
  }
};

export const setClinic = (clinicId: string) => {
  return { type: SET_CLINIC, payload: clinicId };
};

export const setIsSidebarVisible = (visibility: boolean) => {
  return { type: SET_IS_SIDEBAR_VISIBLE, payload: visibility };
};
