import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { getMemberId } from "shared/state/ui/member/selectors";
import { patientQuickListForStaffUserQueryKey } from "./../memberSearch/useMemberSearchResults";
import { selectLoggedInUser } from "./selectors";
import { fetchPatientAccessApi } from "shared/fetch/src/apis/PatientAccessApi";

const useAddToPatientHistory = (memberId?: string) => {
  const previousMemberId = useSelector(getMemberId);
  const queryClient = useQueryClient();
  const user = selectLoggedInUser();

  const mutation = useMutation(() => {
    return fetchPatientAccessApi
      .addToPatientHistory({
        id: memberId!,
      })
      .finally(() => {
        user?.id &&
          queryClient.invalidateQueries(
            patientQuickListForStaffUserQueryKey(user?.id?.toString())
          );
      })
      .catch(() => "An Error has occured");
  });

  React.useEffect(() => {
    if (previousMemberId !== memberId && memberId) {
      mutation?.mutateAsync();
    }
  }, [memberId, previousMemberId]);

  return {
    ...mutation,
  };
};

export default useAddToPatientHistory;
