import React, { useState } from "react";
import Button from "components/Button";
import { useNavigate } from "react-router";
import { RunMutation } from "redux-query-react";
import useXoCheckin from "shared/features/checkin/useXoCheckin";
import useScheduledVisitMemberCheckInStateController from "../useScheduledVisitMemberCheckInStateController";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { QueryObserverResult } from "@tanstack/react-query";
import { ReferralComponentCoreStateEnum } from "shared/api/src/models/ReferralComponentCore";
import { UpcomingAppointmentsResponse } from "shared/fetch/src/models/UpcomingAppointmentsResponse";
import { GetOrCreateXoCheckinRequest } from "shared/fetch/src/apis/CheckinApi";
import { CheckinStatusEnum } from "shared/fetch/src/models/CheckinStatusEnum";

interface IProps {
  color?: any;
  memberId: string;
  hasCheckin: boolean;
  hasChevron?: boolean;
  componentId: string;
  appointmentId: string;
  isVisitEligibleForCheckin: boolean;
  componentState: ReferralComponentCoreStateEnum;
  isInsideAttachmentsAndAccommodationsBorder?: boolean;
  refreshComponent?: RunMutation;
  refreshUpcomingAppointments?: () => Promise<
    QueryObserverResult<UpcomingAppointmentsResponse, unknown>
  >;
}
const CheckInButton: React.FC<IProps> = ({
  color,
  memberId,
  hasChevron,
  hasCheckin,
  componentId,
  appointmentId,
  componentState,
  refreshComponent,
  isVisitEligibleForCheckin,
  refreshUpcomingAppointments,
  isInsideAttachmentsAndAccommodationsBorder,
}) => {
  const navigate = useNavigate();

  const { shouldElementRender, label } =
    useScheduledVisitMemberCheckInStateController({
      hasCheckIn: hasCheckin,
      isVisitEligibleForCheckIn: isVisitEligibleForCheckin,
      uiElementKey: "primaryCtaButton",
      componentState,
    });

  const toCheckin = `/members/${memberId}/conversations/${appointmentId}/component/${componentId}/check-in`;
  const { xoCheckin } = useXoCheckin();
  const getOrCreateCheckin = (request: GetOrCreateXoCheckinRequest) =>
    xoCheckin({ id: request.id });

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    const { value: checkIn, response } = await getOrCreateCheckin({
      id: componentId,
    });

    const hasNoCheckinWizard =
      response.status === 201 &&
      checkIn.status === CheckinStatusEnum.Pending;

    if (hasNoCheckinWizard) {
      refreshUpcomingAppointments && (await refreshUpcomingAppointments());
      refreshComponent && (await refreshComponent());
      setIsLoading(false);
    } else {
      navigate(toCheckin);
    }
  };

  if (!shouldElementRender) {
    return null;
  }

  return (
    <Button
      onClick={handleClick}
      isLoading={isLoading}
      color={color || "primary"}
      name={label}
      data-testid={
        isInsideAttachmentsAndAccommodationsBorder
          ? "check-in-button-inside-attachments-section"
          : "check-in-button-outside-attachments-section"
      }
    >
      {label} {hasChevron ? <ChevronRightIcon /> : ""}
    </Button>
  );
};

export default CheckInButton;
