import { useQuery } from "@tanstack/react-query";
import {
  MemberEnrolledDevicesRequest,
  fetchMemberEnrolledDevicesApi,
} from "shared/fetch/src/apis/MemberEnrolledDevicesApi";

import { selectNativeAppState } from "shared/state/nativeApp/selectors";

export const memberEnrolledDevicesQueryKey = (id: string | number) => [
  "memberEnrolledDevices",
  id?.toString(),
];

export default (request: MemberEnrolledDevicesRequest) => {
  const { isApp } = selectNativeAppState();
  const { data: memberEnrolledDevices, isInitialLoading: isLoading } = useQuery(
    memberEnrolledDevicesQueryKey(request.id),
    () => fetchMemberEnrolledDevicesApi.memberEnrolledDevices(request),
    {
      enabled: !!request.id && request.id !== "undefined" && isApp,
      staleTime: 1000 * 50,
    }
  );

  return {
    memberEnrolledDevices,
    isLoading,
  };
};
