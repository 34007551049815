import { useQuery } from "@tanstack/react-query";
import { GetPaymentHistoryRequest, fetchMemberPaymentsApi } from "shared/fetch/src/apis/MemberPaymentsApi";

export const memberPaymentHistoryInfoQueryKey = (
  request: GetPaymentHistoryRequest
) => ["paymentHistory", request];

export default (requestParameters: GetPaymentHistoryRequest) => {
  const { data, isLoading, isPreviousData } = useQuery(
    memberPaymentHistoryInfoQueryKey(requestParameters),
    () => fetchMemberPaymentsApi.getPaymentHistory(requestParameters),
    {
      keepPreviousData: true,
    }
  );

  return {
    isPreviousData,
    paymentsHistory: data?.results,
    allItemsCount: data?.count,
    unpaidItemsCount: data?.unpaidItemsCount,
    paidItemsCount: data?.paidItemsCount,
    estimatedItemsCount: data?.estimatedItemsCount,
    isPending: isLoading,
    paginationMetadata: {
      page: data?.page,
      count: data?.last,
      hideNextButton: data?.page === data?.last,
      hidePrevButton: data?.page === 1,
    },
  };
};
