import { useQuery } from "@tanstack/react-query";
import {
  fetchPanelCareTeamApi,
  GetPanelCareTeamRequest,
} from "shared/fetch/src/apis/PanelCareTeamApi";

export const panelCareTeamQueryKey = ({
  id,
  memberFacing,
  filterViewingCtm,
}: GetPanelCareTeamRequest) => [
  "panelCareTeam",
  "memberId",
  id?.toString(),
  "memberFacing",
  memberFacing,
  "filterViewingCtm",
  filterViewingCtm,
];

export default ({
  id,
  memberFacing,
  filterViewingCtm,
}: GetPanelCareTeamRequest) => {
  const { data: panelCareTeam, isLoading: isPending } = useQuery(
    panelCareTeamQueryKey({ id, memberFacing, filterViewingCtm }),
    () =>
      fetchPanelCareTeamApi.getPanelCareTeam({
        id,
        memberFacing,
        filterViewingCtm,
      })
  );

  return {
    panelCareTeam,
    isPending,
  };
};
