import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import {
  ProxyMinorRequest,
  fetchMemberTransitionApi,
} from "shared/fetch/src/apis/MemberTransitionApi";
import { showSnackbar } from "shared/state/ui/snackbar";

const useProxyMinor = (minorFirstName: string | undefined) => {
  const dispatch = useDispatch();

  const proxyMinor = useMutation((request: ProxyMinorRequest) => {
    return fetchMemberTransitionApi
      .proxyMinor(request)
      .then(() => {
        dispatch(
          showSnackbar(
            `Success! ${minorFirstName} is now nested as a proxy account.`,
            "success"
          )
        );
      })
      .catch(() => {
        dispatch(
          showSnackbar(
            `There was an error nesting ${minorFirstName} as a proxy account.`,
            "danger"
          )
        );
      });
  });

  return proxyMinor;
};

export default useProxyMinor;
