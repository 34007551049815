import React, { FunctionComponent } from "react";

import { useAuthorizedUrl } from "shared/features/auth/useAuthorizedUrl";

import MemberIdCard, { LabelAppearance } from "./MemberIdCard";
import smallLogo from "styles/images/logo/small-logo.png";
import Avatar from "components/Avatar";
import Typography from "components/Typography";
import { useSelector } from "react-redux";
import { getUser } from "shared/features/user";
import styles from "./index.module.css";
import ButtonBase from "components/Button/ButtonBase";
import { Link } from "components/Button";
import { UserInfo } from "shared/fetch/src/models/UserInfo";
import { CTMInfo } from "shared/fetch/src/models/CTMInfo";

interface IProps {
  user?: UserInfo;
  size?: "small" | "medium";
  avatarSize?: "small" | "medium";
  tertiaryLinkAppearance?: boolean;
  color?: string;
  labelAppearance?: LabelAppearance;
  showEligibilityMessaging?: boolean;
  isBold?: boolean;
  isTimeline?: boolean;
  isQueue?: boolean;
  nameOnly?: boolean;
  cctAssigned?: boolean;
  returnTo?: string;
  noAvatar?: boolean;
  noDescription?: boolean;
  travelState?: string;
}

const UserWithTitle: FunctionComponent<IProps> = (props: IProps) => {
  const {
    user,
    tertiaryLinkAppearance,
    color,
    showEligibilityMessaging,
    labelAppearance = "body",
    isBold,
    isTimeline,
    isQueue,
    size,
    avatarSize,
    nameOnly,
    cctAssigned,
    returnTo,
    noAvatar,
    noDescription,
    travelState,
  } = props;
  const currentUser = useSelector(getUser);
  const isCreator = currentUser?.id?.toString() === user?.id?.toString();
  const classes = styles;
  const isXoSystem = user?.name === "Crossover Health";
  const authorizedAvatarUrl = useAuthorizedUrl(user?.avatarUrl);
  let avatarLabel: JSX.Element | string = "You";

  const avatarStyle = {
    height: avatarSize === "small" ? 32 : 36,
    width: avatarSize === "small" ? 32 : 36,
  };

  if (currentUser?.ctm && !isCreator && user?.type === "member" && !nameOnly) {
    avatarLabel = (
      <MemberIdCard
        showEligibilityMessaging={showEligibilityMessaging}
        member={user}
        tertiary={tertiaryLinkAppearance}
        labelAppearance={labelAppearance}
        size={size}
        cctAssigned={cctAssigned}
        travelState={travelState}
      />
    );
  } else if (currentUser && user && !isCreator) {
    const displayName = currentUser?.ctm
      ? user.legalAndPreferredName
      : user.name;
    avatarLabel = (
      <Typography
        className={classes[color!]}
        fontWeightBold={isTimeline ? isBold : true}
      >
        {displayName}
      </Typography>
    );
  }

  if (noAvatar) {
    return (
      <span>
        {/* @ts-ignore: Object is possibly 'null'. */}
        <ButtonBase
          onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
          className={classes.memberProfileLink}
          component={Link}
          to={user?.type === "member" && `/members/${user?.id}`}
          aria-label="Member profile"
        >
          {avatarLabel}
        </ButtonBase>
      </span>
    );
  }

  return (
    <div className={styles.avatarContainer} data-testid="user-with-title">
      <Avatar
        linkTo={user?.type === "member" ? `/members/${user?.id}` : ""}
        size="small"
        alt={user?.legalName}
        // @ts-ignore: Object is possibly 'null'.
        src={isXoSystem ? smallLogo : authorizedAvatarUrl}
        label={avatarLabel}
        isBold={isBold}
        isTimeline={isTimeline}
        isQueue={isQueue}
        style={avatarStyle}
        descriptions={!noDescription ? (user as CTMInfo)?.guilds : undefined}
        data-e2e="avatar"
        returnTo={returnTo}
        isPresentational
      />
    </div>
  );
};

export default UserWithTitle;
