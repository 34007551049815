import { useQuery } from "@tanstack/react-query";

import { store } from "shared/state/StateManagement";

import { updateEntities } from "redux-query";
import {
  FetchEpisodeOfCareRequest,
  fetchEpisodesOfCareApi,
} from "shared/fetch/src/apis/EpisodesOfCareApi";
import { ActionableItemSummaryFromJSON } from "shared/fetch/src/models/ActionableItemSummary";

interface FetchEpisodeOfCareHookRequest
  extends Omit<FetchEpisodeOfCareRequest, "id"> {
  id?: string;
  memberId?: string;
}

export const episodeQueryKey = (id?: string | number) => [
  "episodes",
  id?.toString(),
];

interface UseFetchOptions {
  enabled?: boolean;
}

export const useFetchEpisode = (
  request: FetchEpisodeOfCareHookRequest,
  options: UseFetchOptions = {}
) => {
  const enabled =
    typeof options.enabled !== "undefined" ? options.enabled : !!request.id;

  const {
    data,
    refetch,
    isInitialLoading: isPending,
    isFetching,
  } = useQuery(
    episodeQueryKey(request.id),
    () => {
      if (!request.id) {
        return;
      }

      return fetchEpisodesOfCareApi
        .fetchEpisodeOfCare({ ...request, id: request.id.toString() })
        .then((resp) => {
          if (resp?.actionableItems) {
            const actionableItems: any = resp.actionableItems;

            Object.keys(actionableItems)?.forEach((key: string) => {
              actionableItems[key] = actionableItems?.[key]?.map(
                ActionableItemSummaryFromJSON
              );
            });

            resp.actionableItems = actionableItems;
          }

          return resp;
        })
        .then((resp) => {
          store.dispatch(
            updateEntities({
              episode: (_prev: any) => {
                return resp;
              },
            })
          );
          return resp;
        });
    },
    {
      enabled,
    }
  );

  return {
    data,
    refetch,
    isPending,
    isFetching,
  };
};
