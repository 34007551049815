import { useInfiniteQuery } from "@tanstack/react-query";
import reduce from "lodash/reduce";
import { useEffect } from "react";
import { selectFeatures } from "shared/features/featureFlags/selectors";
import { selectLoggedInUser } from "../user/selectors";
import hasInlineMessaging from "utils/hasInlineMessaging";
import { fetchEpisodesOfCareApi } from "shared/fetch/src/apis/EpisodesOfCareApi";
import { TimelineEpisodeTimelineEventsOutput } from "shared/fetch/src/models/TimelineEpisodeTimelineEventsOutput";
import { TimelineEventOutput } from "shared/fetch/src/models/TimelineEventOutput";

export const episodeTimelineEventsQueryKey = (id?: string | number) => [
  "episode",
  id?.toString(),
  "timeline_events",
];

let fetching = false;

const useFetchEpisodeTimelineEvents = (episodeId: string, order?: string) => {
  const featureFlags = selectFeatures();
  const user = selectLoggedInUser();

  const {
    data,
    fetchNextPage: fetchNextPageOriginal,
    refetch: refetchEpisodeTimelineEvents,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    episodeTimelineEventsQueryKey(episodeId),
    ({ pageParam = 1 }) => {
      return fetchEpisodesOfCareApi.fetchEpisodeOfCareTimelineEvents({
        id: episodeId,
        page: pageParam,
        order,
      });
    },
    {
      getNextPageParam: (lastPage: TimelineEpisodeTimelineEventsOutput) => {
        if (lastPage.results?.page === lastPage.results?.last) {
          return false;
        }

        return (lastPage?.results?.page || 0) + 1;
      },
      keepPreviousData: true,
    }
  );

  const fetchNextPage = () => {
    if (!fetching && hasNextPage) {
      fetching = true;
      fetchNextPageOriginal();
    }
  };

  const timelineEvents = reduce(
    data?.pages,
    (
      acc: TimelineEventOutput[] | [],
      currentValue: TimelineEpisodeTimelineEventsOutput
    ) => {
      const fetchedTimelineEvents = currentValue.results?.timelineEvents || [];
      return [...acc, ...fetchedTimelineEvents];
    },
    []
  );

  const totalUnreadComponents = reduce(
    data?.pages,
    (acc: number, currentValue) => {
      acc = currentValue.totalUnread;
      return acc;
    },
    0
  );

  useEffect(() => {
    fetching = false;
    const fetchedUnreadComponentCount = reduce(
      timelineEvents,
      (acc: number, te: TimelineEventOutput) => {
        const unreadComponents = te?.components?.filter(
          (comp) =>
            !comp.viewedAt && comp.creatorInfo?.id !== user?.id?.toString()
        );
        return unreadComponents?.length ? acc + unreadComponents?.length : acc;
      },
      0
    );
    if (
      totalUnreadComponents > fetchedUnreadComponentCount &&
      hasInlineMessaging(user, featureFlags)
    ) {
      fetchNextPage();
    }
  }, [timelineEvents.length]);

  return {
    timelineEvents,
    totalUnreadComponents,
    fetchNextPage,
    refetchEpisodeTimelineEvents,
    hasNextPage,
    isFetchingNextPage,
  };
};

export default useFetchEpisodeTimelineEvents;
