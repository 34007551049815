import React from "react";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import SpaOutlinedIcon from "@mui/icons-material/SpaOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import HistoryIcon from "@mui/icons-material/History";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";

import { SvgIcon, SvgIconProps, useMediaQuery, useTheme } from "@mui/material";
import { selectFeatures } from "shared/features/featureFlags/selectors";
import { JsonUser as User } from "shared/fetch/src/models/JsonUser";
import { useGetCurrentUserPermissions } from "hooks/useAccess";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";

export interface NavRoute {
  name: string;
  pathname: string;
  permission: PermissionsEnum;
  e2e: string;
  mobileOnly?: boolean;
  icon?: JSX.Element;
}

export interface NavRoutesObject {
  member: NavRoute[];
  ctm: NavRoute[];
}

const GridViewIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M3 3v8h8V3H3zm6 6H5V5h4v4zm-6 4v8h8v-8H3zm6 6H5v-4h4v4zm4-16v8h8V3h-8zm6 6h-4V5h4v4zm-6 4v8h8v-8h-8zm6 6h-4v-4h4v4z" />
  </SvgIcon>
);

const HealthAndSafetyOutlinedIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M10.5 13H8v-3h2.5V7.5h3V10H16v3h-2.5v2.5h-3V13zM12 2 4 5v6.09c0 5.05 3.41 9.76 8 10.91 4.59-1.15 8-5.86 8-10.91V5l-8-3zm6 9.09c0 4-2.55 7.7-6 8.83-3.45-1.13-6-4.82-6-8.83v-4.7l6-2.25 6 2.25v4.7z" />
  </SvgIcon>
);

const navBarRoutes = () => {
  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.only("xs"));
  const features = selectFeatures();

  const ctmFlaggedRoutes = [];

  if (features.hasDailyScheduleXop() || features.hasXOCal()) {
    ctmFlaggedRoutes.push({
      name: "Schedule",
      pathname: "/schedule",
      permission: PermissionsEnum.CtmEpisodesRead,
      e2e: "schedule-link",
      icon: <EventOutlinedIcon />,
    });
  }

  return {
    member: [
      {
        name: "Dashboard",
        pathname: "/",
        permission: PermissionsEnum.MemberHomepageRead,
        e2e: "member-dashboard-link",
        icon: <GridViewIcon />,
      },
      {
        name: "Care History",
        pathname: "/care-history",
        permission: PermissionsEnum.MemberHomepageRead,
        e2e: "care-history-link",
        icon: <HistoryIcon />,
      },
      {
        name: "Health Records",
        pathname: "/health-records",
        permission: PermissionsEnum.HealthRecordsRead,
        e2e: "health-records-link",
        icon: <HealthAndSafetyOutlinedIcon />,
      },
      {
        name: isXSmallScreen ? "Care Team" : "Meet Your Team",
        pathname: "/providers",
        permission: PermissionsEnum.MeetYourTeamRead,
        e2e: "meet-your-team-link",
        icon: <GroupOutlinedIcon />,
      },
    ],
    ctm: [
      {
        name: "Queue",
        pathname: "/",
        permission: PermissionsEnum.CtmEpisodesRead,
        e2e: "care-team-link",
        icon: <ListAltOutlinedIcon />,
      },
      {
        name: "Conversations",
        pathname: "/conversations",
        permission: PermissionsEnum.CtmEpisodesRead,
        e2e: "eoc-link",
        icon: <ForumOutlinedIcon />,
      },
      {
        name: "Proactive",
        pathname: "/proactive",
        permission: PermissionsEnum.CtmEpisodesRead,
        e2e: "proactive-link",
        icon: <SpaOutlinedIcon />,
      },
      {
        name: "Tools",
        pathname: "/tools",
        permission: PermissionsEnum.CmsToolsRead,
        e2e: "tools-link",
        icon: <BuildOutlinedIcon />,
      },
      ...ctmFlaggedRoutes,
      {
        name: "Slata",
        pathname: "/slata",
        permission: PermissionsEnum.CmsSlataupload,
        e2e: "slata-link",
        icon: <BuildOutlinedIcon />,
      },
    ],
  };
};

const useNavBarRoutes = (user: User) => {
  const features = selectFeatures();
  const routes: NavRoutesObject = navBarRoutes();
  const currentUserPermissions = useGetCurrentUserPermissions();

  let userNav = user.ctm ? routes.ctm : routes.member;

  if (!features.hasHealthProfile()) {
    userNav = userNav.filter(
      (navLink) => navLink.pathname !== "/health-records"
    );
  }

  userNav = userNav.filter((navOption) =>
    currentUserPermissions.includes(navOption?.permission)
  );

  return userNav;
};
export default useNavBarRoutes;
