import React, { useEffect, useState } from "react";
import Dialog from "components/Dialog";
import DialogActions from "components/Dialog/DialogActions";
import Button from "components/Button";
import DialogContent from "components/Dialog/DialogContent";
import DialogTitle from "components/Dialog/DialogTitle";
import classes from "./index.module.css";
import Paper from "components/Paper";
import { PaperProps } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CssTypography from "components/CssTypography";
import parseDoB from "shared/utils/parseDoB";
import useFormatDate from "shared/utils/useFormatDate";
import CardSection from "components/Card/CardSection";
import useActivatePatientAccount from "shared/features/accounts/useActivatePatientAccount";
import { useNavigate } from "react-router";
import Checkbox from "components/Checkbox";
import Tooltip from "components/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import ButtonBase from "components/Button/ButtonBase";
import PrimaryAccountHolderAddressSection from "./PrimaryAccountHolderAddressSection";
import { StyledTextField, PhoneSection } from "./PhoneSection";
import { getPrimaryAccountHolderAddress } from "features/memberSearch/utils";
import {
  memberSearchResultsQueryKey,
  patientQuickListForStaffUserQueryKey,
} from "shared/features/memberSearch/useMemberSearchResults";
import { useQueryClient } from "@tanstack/react-query";
import { selectLoggedInUser } from "shared/features/user/selectors";
import { calculateAge } from "utils/calculateAge";
import { OutputMemberSearchSchemaProps } from "shared/fetch/src/models/OutputMemberSearchSchemaProps";
import { ListMembersRequest } from "shared/fetch/src/apis/MemberSearchResultsApi";
import { ValidateFields } from "components/AccountAndSettings/utils";

function PaperComponent(props: PaperProps) {
  return <Paper {...props} classes={{ root: classes.paper }} />;
}

interface IProps {
  showProxyOption: boolean;
  open: boolean;
  closeModal: () => void;
  member: OutputMemberSearchSchemaProps;
  openProxyModal?: () => void;
  searchRequest?: ListMembersRequest;
  phoneError: string;
  emailError: string;
}

const ActivateAccountModal: React.FC<IProps> = ({
  open,
  closeModal,
  member,
  openProxyModal,
  searchRequest,
}) => {
  // The first design for this modal called for a showing an option to proxy/nest at the same time
  // a teen user is green plussed. Dev is being asked to change that flow so that teens (age 12-17) are
  // shown the same modal as adult users. We previously used the showProxyOption prop to determine whether to
  // display the teen version of the modal, so something like
  // const isTeenModal = showProxyOption
  // I am not convinced product will not in the future ask for the teen version of the modal again, so I'm retaining
  // the prop in the interface, but for the time being isTeenModal will be harcoded to false
  const isTeenModal = false;
  const [isEmailTouched, setIsEmailTouched] = useState<boolean>(false);
  const [isPhoneTouched, setIsPhoneTouched] = useState<boolean>(false);
  const [email, setEmail] = useState<string | undefined>(member?.email);
  const [cellPhone, setCellPhone] = useState<string | undefined>(
    member?.cellPhone
  );
  const [homePhone, setHomePhone] = useState<string | undefined>(
    member?.homePhone
  );

  const getDefaultPhone = () => {
    // order matters to use cell as default
    if (member?.cellPhone) {
      return { phoneType: "Mobile", displayedPhoneNumber: member.cellPhone };
    }
    if (member?.homePhone) {
      return { phoneType: "Home", displayedPhoneNumber: member.homePhone };
    }
    return { phoneType: undefined, displayedPhoneNumber: undefined };
  };
  const {
    phoneType: initialPhoneType,
    displayedPhoneNumber: initialPhoneNumber,
  } = getDefaultPhone();

  const [phoneType, setPhoneType] = useState<string | undefined>(
    initialPhoneType
  );

  const [isFirstPhoneTypeSelection, setIsFirstPhoneTypeSelection] = useState(
    !initialPhoneType
  ); // false if elig number populates & phoneType is preselected

  const [displayedPhoneNumber, setDisplayedPhoneNumber] = useState<
    string | undefined
  >(initialPhoneNumber);
  const [emailValidationError, setEmailValidationError] = useState<string>("");
  const [phoneValidationError, setPhoneValidationError] = useState<string>("");
  const [phoneTypeError, setPhoneTypeError] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const emailInvalidMsg = "Please enter a valid email address.";
  const phoneInvalidMsg = "Please enter a valid phone number.";

  // @ts-ignore: Object is possibly 'null'
  const ageInYears = calculateAge(new Date(member?.dob));
  const isChild = member?.minor && ageInYears < 12;
  const isTeen = member?.minor && ageInYears > 11 && ageInYears < 18;

  const [isSubmittingProxyRequest, setIsSubmittingProxyRequest] =
    useState(false);
  const formatDate = useFormatDate();
  // @ts-ignore: Object is possibly 'null'
  const parsedDoB = parseDoB(member?.dob);
  const memberDoB = formatDate(parsedDoB, "MM/dd/yyyy");
  const { mutateAsync: activatePatientAccount } = useActivatePatientAccount(
    isChild ? member?.firstName : undefined
  );
  const queryClient = useQueryClient();
  const user = selectLoggedInUser();
  const navigate = useNavigate();

  const checkFields = () => {
    const validatedFields = ValidateFields({
      email,
      cellPhone: displayedPhoneNumber,
    });
    const emailValid = validatedFields.email;
    const phoneValid = validatedFields.cellPhone; // cell & home phone validation are the same
    const isValid = emailValid && phoneValid && phoneType;

    setIsSubmitDisabled(!isValid);

    if (isEmailTouched) {
      setEmailValidationError(emailValid ? "" : emailInvalidMsg);
    }
    if (isPhoneTouched) {
      setPhoneValidationError(phoneValid ? "" : phoneInvalidMsg);
    }

    if (isTeen && !email) {
      setEmailValidationError(""); // email field displayed but not required for teen
    }

    if (isTeen && phoneValid && phoneType) {
      setIsSubmitDisabled(false);
    }
  };

  const determinePhoneSubmission = () => {
    // per Product request, only respect changes to the selected radio button field
    // the hidden field, if deleted or edited, will not be altered or validated;
    // its initial eligibilty field value will be pushed to the user
    // (the original elig entry should already be validated)
    // further updates can be made in user accounts & settings
    if (phoneType === "Mobile") {
      setCellPhone(displayedPhoneNumber);
      setHomePhone(member?.homePhone);
    } else if (phoneType === "Home") {
      setHomePhone(displayedPhoneNumber);
      setCellPhone(member?.cellPhone);
    }
  };

  useEffect(() => {
    if (open) {
      checkFields();
    }
  }, [open, email, displayedPhoneNumber, phoneType]);

  /* eslint-disable no-nested-ternary */
  const variableCaptionText = isTeen
    ? `To activate a standalone account, a create password email will be sent 
  to the teen.`
    : isChild
    ? `When a minor's account is activated, the primary account holder will have
  proxy access.`
    : `In order to activate an account, a create password email will be
  sent to the Member.`;

  const activationCaption =
    variableCaptionText +
    " Confirm Member's information matches before activating the account.";

  const handleEmailBlur = () => {
    checkFields();
  };

  const handlePhoneBlur = () => {
    checkFields();
    determinePhoneSubmission();
  };

  const onClose = () => {
    closeModal();
    setEmail(undefined);
    setDisplayedPhoneNumber(undefined);
    setIsSubmittingProxyRequest(false);
  };

  const onConfirm = async () => {
    const validatedFields = ValidateFields({
      email,
      cellPhone: displayedPhoneNumber,
    });
    const emailValid = validatedFields.email;
    const phoneValid = validatedFields.cellPhone; // cell & home phone validation are the same

    setEmailValidationError(emailValid ? "" : emailInvalidMsg);
    setPhoneValidationError(phoneValid ? "" : phoneInvalidMsg);

    if (!phoneType) {
      setPhoneTypeError(true);
    } else {
      setPhoneTypeError(false);
    }

    if (!emailValid || !phoneValid) {
      return;
    }

    activatePatientAccount({
      greenPlusPatientRequestBody: {
        baseId: member.id!,
        // @ts-ignore: Object is possibly 'null'
        // Avoid email uniqueness error & accidental emails to minors
        // TODO move logic to add_patients in BE as eventually isChild age will vary
        email: isChild ? null : email,
        cellPhone,
        homePhone,
      },
    })
      .then((response) => {
        setEmail(undefined);
        setDisplayedPhoneNumber(undefined);
        closeModal();
        if (searchRequest) {
          queryClient.invalidateQueries(
            memberSearchResultsQueryKey(searchRequest)
          );
        }
        if (user?.id) {
          queryClient.invalidateQueries(
            patientQuickListForStaffUserQueryKey(user?.id)
          );
        }
        if (isTeenModal && isSubmittingProxyRequest && openProxyModal) {
          openProxyModal();
          setIsSubmittingProxyRequest(false);
        } else {
          if (!member?.minor) {
            navigate(`/members/${response.id}/demographics`);
          }
        }
      })
      .catch(() => {
        onClose();
      });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setIsEmailTouched(true);
    checkFields();
  };

  const handlePhoneInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPhoneNumber = event.target.value;
    setDisplayedPhoneNumber(newPhoneNumber);

    if (phoneType === "Mobile") {
      setCellPhone(newPhoneNumber);
    } else if (phoneType === "Home") {
      setHomePhone(newPhoneNumber);
    }
    setIsPhoneTouched(true);
    checkFields();
    determinePhoneSubmission();
  };

  const handlePhoneTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedType = event.target.value as "Mobile" | "Home";
    setPhoneType(selectedType);
    setPhoneTypeError(false);

    if (selectedType === "Mobile") {
      // Set the cell phone number to the displayed phone number if it exists
      setCellPhone(displayedPhoneNumber || member?.cellPhone);
      // Reset the hidden field (homePhone) to its original value
      setHomePhone(member?.homePhone);
    } else if (selectedType === "Home") {
      // Set the home phone number to the displayed phone number if it exists
      setHomePhone(displayedPhoneNumber || member?.homePhone);
      // Reset the hidden field (cellPhone) to its original value
      setCellPhone(member?.cellPhone);
    }

    // Ensure displayedPhoneNumber is synced with the selected type
    if (isFirstPhoneTypeSelection) {
      setIsFirstPhoneTypeSelection(false);
      setDisplayedPhoneNumber(displayedPhoneNumber);
    } else {
      setDisplayedPhoneNumber(
        selectedType === "Mobile" ? cellPhone : homePhone
      );
    }
    checkFields();
  };

  const handleProxyCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSubmittingProxyRequest(!isSubmittingProxyRequest);
    setIsSubmitDisabled(!e.target.checked);
  };

  const tooltipCopy =
    "Submitting a proxy consent will create a nested account for the teen. The primary account holder will have access to the teen’s health records, and the teen will not have access to their own account.";
  const helperText =
    "This email address will only be used for notification emails from Crossover. It is recommended to use Member’s personal email address. No health data will be sent via email.";

  return (
    <>
      <Dialog
        open={open}
        PaperComponent={PaperComponent}
        ariaLabelledBy="alert-dialog-title"
        onClose={onClose}
      >
        <div className={classes.titleContainer}>
          <DialogTitle
            id="alert-dialog-title"
            data-e2e="activate-account-modal"
            className={classes.title}
          >
            {isTeenModal
              ? "Activate account and submit proxy"
              : `Activate account ${!isChild ? "and create password" : ""}`}
          </DialogTitle>
          <IconButton
            aria-label="Activate account dialog close button"
            data-e2e="activate-account-modal-close-btn"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <CardSection bottomDivider style={{ padding: 0 }}>
          <DialogContent className={classes.dialogContent}>
            <CssTypography appearance="smallBody" className={classes.subtitle}>
              {isTeenModal
                ? `To activate a standalone account, a create password email will be sent
        to the teen. Confirm Member's information matches before activating the account.`
                : activationCaption}
            </CssTypography>
            <div className={classes.memberInfoBorder}>
              <div className={classes.memberInfo}>
                <div>
                  {member?.firstName} {member?.lastName}
                </div>
                <div>{memberDoB}</div>
                <div>{member?.employer}</div>
              </div>
            </div>
            {isChild ? (
              <>
                <PrimaryAccountHolderAddressSection
                  primaryAccountHolderAddress={getPrimaryAccountHolderAddress(
                    member?.primaryAccountHolder
                  )}
                />
                <PhoneSection
                  phoneType={phoneType}
                  setPhoneType={setPhoneType}
                  displayedPhoneNumber={displayedPhoneNumber}
                  setDisplayedPhoneNumber={setDisplayedPhoneNumber}
                  phoneValidationError={phoneValidationError}
                  setPhoneValidationError={setPhoneValidationError}
                  phoneTypeError={phoneTypeError}
                  setPhoneTypeError={setPhoneTypeError}
                  handlePhoneBlur={handlePhoneBlur}
                  handlePhoneInputChange={handlePhoneInputChange}
                  handlePhoneTypeChange={handlePhoneTypeChange}
                  member={member}
                />
              </>
            ) : (
              <>
                {isTeenModal && (
                  <>
                    <div className={classes.proxyOption}>
                      <Checkbox
                        color="secondary"
                        checked={isSubmittingProxyRequest}
                        className={classes.checkbox}
                        onChange={handleProxyCheckbox}
                        inputProps={{
                          "aria-label": "I'm also submitting a proxy approval",
                        }}
                        data-testid="proxy-request-checkbox"
                      />
                      <CssTypography appearance="body">
                        I'm also submitting a proxy approval
                      </CssTypography>
                      <Tooltip
                        title={tooltipCopy}
                        appearance="light"
                        placement="right-start"
                      >
                        <ButtonBase>
                          <InfoIcon className={classes.tooltip} />
                        </ButtonBase>
                      </Tooltip>
                    </div>
                    <CssTypography
                      appearance="smallBody"
                      className={classes.checkboxText}
                    >
                      Once a proxy approval is submitted, the teen will not have
                      separate access to their own account.
                    </CssTypography>
                  </>
                )}
                {!isSubmittingProxyRequest && (
                  <>
                    <StyledTextField
                      variant="filled"
                      label={isTeen ? "Teen's Email" : "Email"}
                      id="activate-account-email"
                      fullWidth
                      margin="dense"
                      onChange={handleEmailChange}
                      onBlur={handleEmailBlur}
                      value={email}
                      error={!!emailValidationError}
                      helperText={emailValidationError || ""}
                      inputProps={{ "aria-label": "Email Address Input" }}
                      style={{ marginTop: "24px" }}
                    />
                    <CssTypography
                      appearance="smallBody"
                      className={classes.subtitle}
                    >
                      {helperText}
                    </CssTypography>
                    <PhoneSection
                      phoneType={phoneType}
                      setPhoneType={setPhoneType}
                      displayedPhoneNumber={displayedPhoneNumber}
                      setDisplayedPhoneNumber={setDisplayedPhoneNumber}
                      phoneValidationError={phoneValidationError}
                      setPhoneValidationError={setPhoneValidationError}
                      phoneTypeError={phoneTypeError}
                      setPhoneTypeError={setPhoneTypeError}
                      handlePhoneBlur={handlePhoneBlur}
                      handlePhoneInputChange={handlePhoneInputChange}
                      handlePhoneTypeChange={handlePhoneTypeChange}
                      member={member}
                    />
                  </>
                )}
              </>
            )}
          </DialogContent>
        </CardSection>
        <DialogActions horizontal className={classes.actions}>
          <Button
            color="primary"
            data-e2e="activate-account-button"
            onClick={onConfirm}
            disabled={isSubmitDisabled}
          >
            {isSubmittingProxyRequest ? "Continue" : "Activate account"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActivateAccountModal;
