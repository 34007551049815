import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { fetchSlotsApi } from "shared/fetch/src/apis/SlotsApi";
import { GetCapacityRequest } from "shared/api/src/apis";
import { CapacityFetchResponse } from "shared/api/src/models/CapacityFetchResponse";
import reduce from "lodash/reduce";

export const providerCapacityQueryKey = (
  //change this
  request: GetCapacityRequest
) => ["providerCapacity", request];

let fetching = false;

const fetchCapacity = (
  request: GetCapacityRequest,
  pageParam: number | string
): CapacityFetchResponse => {
  //@ts-ignore
  return fetchSlotsApi.getCapacity({ ...request, page: pageParam.toString() });
};

export default (request: GetCapacityRequest) => {
  const {
    data,
    isLoading: isFetching,
    fetchNextPage: fetchNextCapacityPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    providerCapacityQueryKey(request),
    ({ pageParam = 1 }) => {
      return fetchCapacity(request, pageParam);
    },
    {
      getNextPageParam: (lastPage) => {
        //@ts-ignore
        if (lastPage.page === lastPage.last || lastPage.results.length === 0) {
          return false;
        }
        //@ts-ignore
        return (lastPage?.page || 0) + 1;
      },
      enabled:
        !!request.id &&
        !!request.startDate &&
        !!request.daysCount &&
        !!request.itemCount &&
        !!request.timezone &&
        !!request.appointmentType &&
        !!request.schedulingType,
    }
  );

  const fetchNextPage = (force?: boolean) => {
    if (hasNextPage && (force || !fetching)) {
      fetching = true;
      fetchNextCapacityPage();
    }
  };

  const providers = reduce(
    data?.pages,
    (acc: any, { results: providerCapacity = [] }) => {
      return [...acc, ...providerCapacity];
    },
    []
  );

  //not sure abt this...see filteredQueueEpisodes
  //in shared/feayures/queue/useGetFilteredQueueEpisodes.ts
  useEffect(() => {
    fetching = false;
  }, [data?.pages?.length]);

  return {
    providers,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  };
};

// const useGetClinicsCapacity = (
//   //change this
//   requestParameters: ITestRequest,
//   options?: any
// ) => {
//   const { data, isLoading, refetch, isFetched } = useQuery(
//     clinicsCapacityQueryKey(requestParameters),
//       //change this
//     () => fetchMemberClinicsApi.getMemberClinics(requestParameters),
//     {
//       enabled: options?.enabled && !!requestParameters.id &&
//       !!requestParameters.startDate && !!requestParameters.daysCount &&
//       !!requestParameters.itemCount  && !!requestParameters.timezone &&
//       !!requestParameters.appointmentType && !!requestParameters.schedulingType,
//     }
//   );

//   return {
//     clinicsCapacity: data?.results,
//     isPending: isLoading,
//     isFinished: isFetched,
//     getClinicsCapacity: refetch,
//   };
// };

// export default useGetClinicsCapacity;
