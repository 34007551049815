import palette from "../palette";
import { customFocusForAllBackgrounds } from "./utils";

export default {
  root: {
    "&.Mui-focusVisible svg": customFocusForAllBackgrounds,
  },
  colorPrimary: {
    "&:hover": {
      backgroundColor: "rgba(28, 116, 171, 0.03)",
    },
    "&.Mui-checked": {
      color: `${palette.blue} !important`,
    },
  },
  colorSecondary: {
    "&.Mui-checked": {
      color: `${palette.blue} !important`,
    },
  },
  focusVisible: {},
};
