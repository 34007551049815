import { useQuery } from "@tanstack/react-query";
import {
  GetMemberSubscriptionRequest,
  fetchMemberPaymentsApi,
} from "shared/fetch/src/apis/MemberPaymentsApi";
import { ListMemberSubscription } from "shared/fetch/src/models/ListMemberSubscription";

export const memberSubscriptionQueryKey = (id?: string | number) => [
  "memberSubscription",
  id?.toString(),
];

const useGetMemberSubscription = (request: GetMemberSubscriptionRequest) => {
  const { data, isInitialLoading, isError, refetch } = useQuery(
    memberSubscriptionQueryKey(request.id),
    () => fetchMemberPaymentsApi.getMemberSubscription(request),
    {
      enabled: false,
    }
  );
  return {
    memberSubscription: (data || {}) as ListMemberSubscription,
    isLoading: isInitialLoading,
    isError,
    getSubscription: refetch,
  };
};

export default useGetMemberSubscription;
