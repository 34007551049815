import { useQuery } from "@tanstack/react-query";
import {
  GetPrescriptionRequest,
  fetchMemberPrescriptionsApi,
} from "shared/fetch/src/apis/MemberPrescriptionsApi";

export const memberPrescriptionsListQueryKey = (id?: string) => [
  "memberPrescriptionsList",
  id?.toString(),
];

const useGetPrescriptions = (request: GetPrescriptionRequest) => {
  const query = useQuery(
    memberPrescriptionsListQueryKey(request.id),
    () => fetchMemberPrescriptionsApi.getPrescription(request),
    {
      enabled: !!request.id && request.id !== "undefined",
      keepPreviousData: true,
    }
  );
  return query;
};

export default useGetPrescriptions;
