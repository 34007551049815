import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { durationConverter, getDaysBetween } from "utils/durationConverter";
import { a11yClick } from "utils/a11yClick";
import useFormatDate from "shared/utils/useFormatDate";
import { getUser } from "shared/features/user/selectors";
import clsx from "clsx";
import UserWithTitle from "components/User";
import { TypographyAppearance } from "components/Typography";
import EocBadge from "components/EocBadge";
import { getFeatures } from "shared/features/featureFlags/selectors";
import Heading from "components/DynamicHeadings";
import EOCActionableItems from "../EOCActionableItems";
import EOCMemberStatus from "../EOCMemberStatus";
import EOCTimeSince from "../EOCTimeSince";
import ActionableItemOwnershipIcon from "../ActionableItemOwnershipIcon";
import SelectEpisodeOwnerModal from "../SelectEpisodeOwnerModal";
import SelectAiOwnerModal from "../SelectAiOwnerModal";
import serviceLineMap from "utils/serviceLineMap";
import styles from "./index.module.css";
import { CssTypography as Typography } from "../../CssTypography";
import { useTheme } from "@mui/material";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import {
  setComponentIdentifier,
  useScrollRestoration,
} from "shared/state/ui/scrollRestoration";
import {
  WorkspaceTabEnum,
  setWorkspacePath,
  WorkspaceSubnavEnum,
} from "shared/state/ui/workspace";
import { ActionableItemSummary } from "shared/fetch/src/models/ActionableItemSummary";
import { EpisodeSummary } from "shared/fetch/src/models/EpisodeSummary";
import { EpisodeSummaryCoreOutputStateEnum } from "shared/fetch/src/models/EpisodeSummaryCoreOutput";

interface IProps {
  index: number;
  eoc: EpisodeSummary;
  returnTo?: string;
  currentPath?: string;
}

export const HighlightsComponent: React.FC<{
  archivedAt: Date;
  createdAt: Date;
  totalMessages: number;
  classes: Record<any, string>;
  options?: {
    boldHeader: boolean;
    bodyAppearance: TypographyAppearance;
  };
}> = ({
  archivedAt,
  createdAt,
  totalMessages,
  classes,
  options = {
    boldHeader: true,
    bodyAppearance: "smallBody",
  },
}) => {
  const formatDate = useFormatDate();
  const bodyAppearance = options.bodyAppearance;

  return (
    <div className={classes?.highlights}>
      <Heading.H
        appearance={bodyAppearance}
        className={clsx(classes.highlightsHeader, "typography-small-body", {
          fontWeightBold: options.boldHeader,
        })}
        cssType
      >
        Highlights:
      </Heading.H>
      <Typography
        appearance={bodyAppearance}
        className={clsx(classes.textAlign, classes.textSecondary)}
        gutterBottom
      >
        Created:{" "}
        <span className={classes?.font}>
          {" "}
          {formatDate(createdAt, "MMM d, yyyy", { fallbackDate: "" })}{" "}
        </span>
      </Typography>
      <Typography
        appearance={bodyAppearance}
        className={clsx(classes.textAlign, classes.textSecondary)}
        gutterBottom
      >
        Archived:{" "}
        <span className={classes?.font}>
          {formatDate(archivedAt, "MMM d, yyyy", { fallbackDate: "" })}{" "}
        </span>
      </Typography>
      <Typography
        appearance={bodyAppearance}
        className={clsx(classes.textAlign, classes.textSecondary)}
      >
        Total Messages: <span className={classes?.font}> {totalMessages}</span>
      </Typography>
    </div>
  );
};

const EOCLineItemContainer: FunctionComponent<IProps> = (props) => {
  const [openSelectOwnerModal, setOpenSelectOwnerModal] = useState(false);
  const [openSelectAiOwnerModal, setOpenSelectAiOwnerModal] = useState(false);
  const [episodeOwner, setEpisodeOwner] = useState(props?.eoc?.owner);
  const [episodeRoles, setEpisodeRoles] = useState(props?.eoc?.roles);
  const [episodeAis, setEpisodeAis] = useState(props?.eoc?.actionableItems);
  const parentDiv = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { eoc } = props;
  const [hasDirectMessage, setHasDirectMessage] = useState(false);
  const theme = useTheme();
  const classes = styles;
  const user = useSelector(getUser);
  const featureFlags = useSelector(getFeatures);
  const scrollRestoration = useScrollRestoration(props.index.toString());

  const {
    id,
    needsActionSince,
    memberInfo,
    serviceLine,
    addedAt,
    lastCtmUpdater,
    totalMessages,
    archivedAt,
    virtualUrgentCare,
    timeSensitive,
    owner,
    cctAssigned,
    category,
    initialRecipientType,
    isVisit,
    travelState,
  } = eoc;

  const showOutOfStateBadge =
    featureFlags.hasOutOfStateScheduling() && user?.ctm;

  const actionableItemsJson = JSON.stringify(props?.eoc?.actionableItems);

  useEffect(() => {
    parentDiv.current && scrollRestoration.setComponent(parentDiv.current);
  }, [parentDiv.current]);

  useEffect(() => {
    setHasDirectMessage(category === "messaging");
  }, [eoc]);

  useEffect(() => {
    setEpisodeAis(props?.eoc?.actionableItems);
  }, [actionableItemsJson]);

  const isArchived = eoc.state === EpisodeSummaryCoreOutputStateEnum.Archived;
  const timeDiff = isArchived
    ? // @ts-ignore: Object is possibly 'null'.
      getDaysBetween(new Date(addedAt), new Date(archivedAt))
    : // @ts-ignore: Object is possibly 'null'.
      getDaysBetween(needsActionSince, new Date());

  const timeSinceSplit =
    isArchived && !(typeof timeDiff === "number")
      ? // @ts-ignore: Object is possibly 'null'.
        durationConverter(addedAt, timeDiff)
      : // @ts-ignore: Object is possibly 'null'.
        durationConverter(needsActionSince);
  // @ts-ignore: Object is possibly 'null'.
  const duration = durationConverter(addedAt).join(" ");

  const timeSinceContainerClass = timeSensitive && classes.timeSinceContainer;
  let timeSinceClass;
  if (isArchived) {
    timeSinceClass = classes.duration;
  } else if (timeSensitive) {
    timeSinceClass = classes.timeSensitive;
  }

  let timeUnit = "";
  if (typeof timeDiff === "number") {
    if (timeDiff > 1) {
      timeUnit = "Days";
    } else {
      timeUnit = "Day";
    }
  } else {
    timeUnit = timeSinceSplit[1];
  }

  const renderBadges = () => {
    return eoc.badges ? eocBadges() : <></>;
  };

  const eocBadges = () => {
    return eoc?.badges?.map((currentBadge) => {
      const unachievedBadge = currentBadge === "UARC";
      const badgeColor: string = unachievedBadge
        ? theme.palette.text.primary
        : theme.palette.white;
      const backgroundColor = unachievedBadge
        ? theme.palette.appBackground?.lightGrey
        : theme.palette.accent?.main;

      return (
        <EocBadge
          key={String(currentBadge)}
          badge={{
            abbreviation: currentBadge,
            color: badgeColor,
            backgroundColor: backgroundColor as string,
          }}
          className={classes.badgeContainer}
        />
      );
    });
  };

  const columnHeaders = () => {
    return (
      <div className={clsx(classes.columnHeaderContainer, classes.layout)}>
        <div
          className={clsx(
            classes.lineItemSection,
            classes.timeContainer,
            classes.columnHeader,
            classes.gridAreaTime,
            classes.textSecondary
          )}
        >
          <Typography appearance="smallBody" gutterBottom>
            TIME
          </Typography>
        </div>
        <div
          className={clsx(
            classes.lineItemSection,
            classes.columnHeader,
            classes.gridAreaMember,
            classes.textSecondary
          )}
        >
          <Typography appearance="smallBody">MEMBER</Typography>
        </div>
        <div
          className={clsx(
            classes.lineItemSection,
            classes.columnHeader,
            classes.gridAreaConvo
          )}
        >
          <Typography appearance="smallBody">CONVERSATION</Typography>
        </div>
        <div
          className={
            isArchived
              ? clsx(
                  classes.columnHeaderArchived,
                  classes.lineItemSection,
                  classes.textSecondary,
                  classes.gridAreaItems
                )
              : clsx(
                  classes.lineItemSection,
                  classes.columnHeader,
                  classes.textSecondary,
                  classes.gridAreaItems
                )
          }
        >
          <Typography appearance="smallBody">ACTION ITEM</Typography>
        </div>
        {!isArchived && (
          <div
            className={clsx(
              classes.aIRadioButtonContainer,
              classes.gridAreaButton
            )}
          />
        )}
      </div>
    );
  };

  const onEpisodeOwnerChange = (ctm: any) => {
    setEpisodeOwner(ctm);
  };

  const onAiOwnerChange = (items: ActionableItemSummary[]) => {
    setEpisodeAis(items);
  };

  const isConversationsRoute = props?.currentPath === "/conversations";
  const displayPopoverOnHover = featureFlags.hasVisitSeparation() && isVisit;

  return (
    <>
      {openSelectOwnerModal && (
        <SelectEpisodeOwnerModal
          open={openSelectOwnerModal}
          toggle={() => setOpenSelectOwnerModal(!openSelectOwnerModal)}
          owner={episodeOwner}
          episodeId={id}
          memberId={memberInfo.id}
          handleOwnerChange={onEpisodeOwnerChange}
          handleRolesUpdate={setEpisodeRoles}
        />
      )}
      {openSelectAiOwnerModal && (
        <SelectAiOwnerModal
          open={openSelectAiOwnerModal}
          toggle={() => setOpenSelectAiOwnerModal(!openSelectAiOwnerModal)}
          // @ts-ignore: Object is possibly 'null'.
          actionableItems={episodeAis}
          memberId={memberInfo.id}
          episodeId={id}
          handleOwnerChange={onAiOwnerChange}
        />
      )}
      {props.index === 0 && columnHeaders()}
      <div
        id={String(props.index)}
        // @ts-ignore: Object is possibly 'null'.
        ref={parentDiv}
        className={clsx(classes.lineItem, "eoc-line-item")}
        key={id}
        data-e2e="eoc-line-item"
        data-testid="eoc-line-item"
        data-eoc={`member-${memberInfo.id}-episode-${id}`}
        onClick={() =>
          navigate(`/members/${memberInfo.id}/conversations/${id}`)
        }
        role="link"
        tabIndex={0}
        onKeyPress={(evt) => {
          if (a11yClick(evt)) {
            navigate(`/members/${memberInfo.id}/conversations/${id}`);
          }
        }}
      >
        <div className={classes.layout}>
          <div
            className={clsx(
              timeSinceContainerClass,
              classes.lineItemSection,
              classes.timeContainer,
              styles.gridAreaTime
            )}
          >
            <div>
              <div className={classes.timeSinceContainerStyles}>
                {isArchived && (
                  <Typography
                    appearance="smallBody"
                    className={classes.duration}
                  >
                    Duration
                  </Typography>
                )}
                <EOCTimeSince
                  styleName={timeSinceClass}
                  lengthOfTime={
                    typeof timeDiff === "number"
                      ? String(timeDiff)
                      : timeSinceSplit[0]
                  }
                  timeSensitive={timeSensitive}
                  timeUnit={timeUnit}
                />
                {renderBadges()}
              </div>
            </div>
          </div>
          <div
            className={clsx(classes.lineItemSection, classes.gridAreaMember)}
          >
            <div className={classes.user}>
              <UserWithTitle
                showEligibilityMessaging
                user={memberInfo}
                tertiaryLinkAppearance
                size="small"
                avatarSize="small"
                isQueue
                cctAssigned={cctAssigned}
                returnTo={props.returnTo}
                labelAppearance="smallBody"
                noAvatar
                travelState={travelState}
              />
            </div>
          </div>

          <div
            data-testid="eoc-line-item-link-to-timeline"
            className={clsx(classes.lineItemSection, classes.gridAreaConvo)}
          >
            <div>
              <EOCMemberStatus
                isArchived={isArchived}
                virtualUrgentCare={virtualUrgentCare}
                guilds={lastCtmUpdater?.guilds}
                reason={serviceLineMap(serviceLine)}
                duration={duration}
                // @ts-ignore: Object is possibly 'null'.
                lastInteraction={lastCtmUpdater?.legalAndPreferredName}
                roles={episodeRoles}
                toggleModal={() =>
                  setOpenSelectOwnerModal(!openSelectOwnerModal)
                }
                owner={owner}
                hasDirectMessage={hasDirectMessage}
                initialRecipientType={initialRecipientType}
                displayPopoverOnHover={displayPopoverOnHover}
                id={id}
              />
            </div>
          </div>
          <div className={clsx(classes.lineItemSection, classes.gridAreaItems)}>
            {isArchived ? (
              <HighlightsComponent
                classes={classes}
                // @ts-ignore: Object is possibly 'null'.
                createdAt={addedAt}
                // @ts-ignore: Object is possibly 'null'.
                archivedAt={archivedAt}
                // @ts-ignore: Object is possibly 'null'.
                totalMessages={totalMessages}
              />
            ) : (
              // archivedAt: Date,
              // createdAt: Date,
              // totalMessages: number,
              // classes: Record<any, string>,
              // <>{HighlightsComponent(archivedAt, addedAt, totalMessages, classes)}</>

              <EOCActionableItems
                user={user}
                items={episodeAis}
                memberId={memberInfo.id}
                // @ts-ignore: Object is possibly 'null'.
                showOutOfStateBadge={showOutOfStateBadge}
              />
            )}
          </div>
          {!isArchived && (
            <div
              className={clsx(
                classes.aIRadioButtonContainer,
                classes.gridAreaButton
              )}
            >
              <ActionableItemOwnershipIcon
                className={classes.aIOwnershipIcon}
                // @ts-ignore: Object is possibly 'null'.
                actionableItems={episodeAis}
                userId={user?.id}
                toggleModal={() =>
                  setOpenSelectAiOwnerModal(!openSelectAiOwnerModal)
                }
              />
              {isConversationsRoute && (
                <ArchiveOutlinedIcon
                  onClick={(e: any) => {
                    e.stopPropagation();
                    dispatch(setComponentIdentifier(props.index.toString()));
                    navigate(
                      `/members/${memberInfo.id}/conversations/${id}?archive`
                    );
                    const path = {
                      tab: WorkspaceTabEnum.FINALIZE,
                      subnav: WorkspaceSubnavEnum.ARCHIVE,
                    };
                    dispatch(setWorkspacePath(path));
                  }}
                  className={classes.archivedIcon}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EOCLineItemContainer;
