import {
  State as WorkspaceState,
  reducer as workspaceReducer,
  getInitialWorkspaceState,
} from "./workspace";
import {
  Filter,
  getInitialSettingsState,
  settingsReducer,
  UiFilter,
} from "./settings";
import { IState as MemberState, member, getInitialMember } from "./member";
import {
  State as SnackbarState,
  snackbar,
  getInitialSnackbarState,
} from "./snackbar";
import {
  State as CalculatorState,
  calculator,
  getInitialCalculatorState,
} from "./calculator";
import {
  State as ConfirmationsDialogState,
  confirmationsDialog,
  getInitialConfirmationsDialogState,
} from "./confirmationsDialog";
import {
  State as MobileComponentModalState,
  mobileComponentModal,
  getInitialMobileComponentModalState,
} from "./mobileComponentModal";

import {
  State as VisitedEpisodeState,
  getInitialVisitedEpisodeState,
  visitedEpisode,
} from "./visitedEpisode";

import {
  IState as SelfScheduleState,
  generateDefaultSelfSchedule,
  selfSchedule,
} from "./selfSchedule";
import {
  IState as ScheduleTabState,
  generateDefaultScheduleTab,
  scheduleTab,
} from "./scheduleTab";

import { combineReducers } from "redux";
import { careTeam } from "./ctm";
import {
  getInitialScrollRestorationState,
  scrollRestoration,
  State as ScrollRestorationState,
} from "./scrollRestoration";
import {
  getInitialCtmSidebar,
  ctmSidebar,
  State as CtmSidebarState,
} from "./ctmSidebar";
import {
  getInitialCareAccessState,
  careAccessReducer,
  State as CareAccessState,
} from "./careAccess";

export interface State {
  workspace: WorkspaceState;
  member: MemberState;
  ctmSidebar: CtmSidebarState;
  snackbar: SnackbarState;
  confirmationsDialog: ConfirmationsDialogState;
  mobileComponentModal: MobileComponentModalState;
  calculator: CalculatorState;
  settings: {
    filters: {
      queue: UiFilter;
      episodes: UiFilter;
      proactive: UiFilter;
    };
    activeFilters: {
      queue: Filter;
      episodes: Filter;
      proactive: Filter;
    };
    isCtmReplyBoxDockedRight: boolean;
  };
  visitedEpisode: VisitedEpisodeState;
  selfSchedule: SelfScheduleState;
  scrollRestoration: ScrollRestorationState;
  careAccess: CareAccessState;
  scheduleTab: ScheduleTabState;
}

export function getInitialUIState(): State {
  return {
    workspace: workspaceReducer(getInitialWorkspaceState(), null),
    member: getInitialMember(),
    ctmSidebar: getInitialCtmSidebar(),
    snackbar: getInitialSnackbarState(),
    confirmationsDialog: getInitialConfirmationsDialogState(),
    mobileComponentModal: getInitialMobileComponentModalState(),
    calculator: getInitialCalculatorState(),
    settings: getInitialSettingsState(),
    visitedEpisode: getInitialVisitedEpisodeState(),
    selfSchedule: generateDefaultSelfSchedule(),
    scrollRestoration: getInitialScrollRestorationState(),
    careAccess: getInitialCareAccessState(),
    scheduleTab: generateDefaultScheduleTab(),
  };
}

export const reducer = combineReducers({
  member,
  snackbar,
  confirmationsDialog,
  mobileComponentModal,
  ctmSidebar,
  careTeam,
  calculator,
  workspace: workspaceReducer,
  settings: settingsReducer,
  visitedEpisode,
  selfSchedule,
  scrollRestoration,
  scheduleTab,
  careAccess: careAccessReducer,
});
