import React from "react";
import Messages from "components/BannerMessages/Messages";
import messageHooks from "components/BannerMessages/hooks";
import { QueryObserverResult } from "@tanstack/react-query";
import { UpcomingAppointmentsResponse } from "shared/fetch/src/models/UpcomingAppointmentsResponse";

const BannerMessages: React.FC<{
  isRefreshingAppointments?: boolean;
  refreshUpcomingAppointments?: () => Promise<
    QueryObserverResult<UpcomingAppointmentsResponse, unknown>
  >;
}> = ({ refreshUpcomingAppointments, isRefreshingAppointments }) => {
  const executeHook = (fn: Function) =>
    fn.call(undefined, {
      isRefreshingAppointments,
      refreshUpcomingAppointments,
    });

  const messages = messageHooks.map(executeHook);

  return <Messages messages={messages} />;
};

export default BannerMessages;
