import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { headsUpNotificationsTypesQueryKey } from "./useGetHeadsUpNotificationTypes";
import { showSnackbar } from "shared/state/ui/snackbar";
import { DeleteHeadsUpNotificationOptOutRequest, fetchHeadsUpApi } from "shared/fetch/src/apis/HeadsUpApi";

interface IProps {
  userId: string;
}
export default ({ userId }: IProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const optimisticUpdate = (
    request: DeleteHeadsUpNotificationOptOutRequest
  ) => {
    queryClient.setQueryData(
      headsUpNotificationsTypesQueryKey(userId),
      (oldData: any) => {
        oldData.forEach((obj: any) => {
          if (obj.component_types) {
            const component = obj.component_types.find(
              (comp: any) => comp.id === request.id
            );
            if (component) {
              component.opted_in = true;
            }
          }
        });
        return oldData;
      }
    );
  };

  const {
    isSuccess,
    isLoading: isOptOutLoading,
    mutate: deleteHeadsUpNotificationOpt,
  } = useMutation(
    (request: DeleteHeadsUpNotificationOptOutRequest) => {
      optimisticUpdate(request); // Call optimistic update before the mutation
      return fetchHeadsUpApi.deleteHeadsUpNotificationOptOut(request);
    },
    {
      onSuccess: () => {
        dispatch(
          showSnackbar(
            "Success! Heads Up notification settings have been updated.",
            "success",
            "auto"
          )
        );
      },
      onError: () => {
        queryClient.invalidateQueries({
          queryKey: headsUpNotificationsTypesQueryKey(userId),
        });
        dispatch(
          showSnackbar("There was a problem clearing notifications.", "danger")
        );
      },
    }
  );

  return {
    isSuccess,
    isOptOutLoading,
    deleteHeadsUpNotificationOpt,
  };
};
