import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { headsUpNotificationsTypesQueryKey } from "./useGetHeadsUpNotificationTypes";
import { showSnackbar } from "shared/state/ui/snackbar";
import { HeadsUpNotificationOptOutRequest, fetchHeadsUpApi } from "shared/fetch/src/apis/HeadsUpApi";

export default () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  let ctmId: string;
  const {
    isSuccess,
    isLoading: isOptInLoading,
    mutate: createHeadsUpNotificationOpt,
  } = useMutation(
    (request: HeadsUpNotificationOptOutRequest) => {
      ctmId = request.id;
      return fetchHeadsUpApi.headsUpNotificationOptOut(request);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: headsUpNotificationsTypesQueryKey(ctmId),
        });
        dispatch(
          showSnackbar(
            "Success! Heads Up notification settings have been updated.",
            "success",
            "auto"
          )
        );
      },
      onError: () => {
        dispatch(
          showSnackbar("There was a problem clearing notifications.", "danger")
        );
      },
    }
  );

  return {
    isSuccess,
    isOptInLoading,
    createHeadsUpNotificationOpt,
  };
};
