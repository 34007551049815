/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DailyScheduleResponse,
    DailyScheduleResponseFromJSON,
    DailyScheduleResponseToJSON,
} from '../models/DailyScheduleResponse'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface GetDailyScheduleRequest {
    clinicId: string;
    date: string;
}


export interface IDailyScheduleApi {
      getDailySchedule: (requestParameters: GetDailyScheduleRequest) => Promise<DailyScheduleResponse>
      getDailyScheduleWithResponse: (requestParameters: GetDailyScheduleRequest) => Promise<{ value: DailyScheduleResponse, response: any}>
}

/**
 * no description
 */
export class DailyScheduleApi extends runtime.BaseAPI {

    /**
     * Get daily schedule
     * Get daily schedule
     */
    getDailyScheduleRaw = async (requestParameters: GetDailyScheduleRequest): Promise<{ runtime: runtime.ApiResponse<DailyScheduleResponse>, response: any}> => {
        if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
            throw new runtime.RequiredError('clinicId','Required parameter requestParameters.clinicId was null or undefined when calling getDailySchedule.');
        }

                        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getDailySchedule.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinic_id'] = requestParameters.clinicId;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/schedule`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => DailyScheduleResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get daily schedule
     * Get daily schedule
     */
    getDailySchedule = async (requestParameters: GetDailyScheduleRequest): Promise<DailyScheduleResponse> => {
        const { runtime } = await this.getDailyScheduleRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get daily schedule
     * Get daily schedule
     */
    getDailyScheduleWithResponse = async (requestParameters: GetDailyScheduleRequest): Promise<{ value: DailyScheduleResponse, response: any}> => {
        const { runtime, response } = await this.getDailyScheduleRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchDailyScheduleApi: IDailyScheduleApi  = new DailyScheduleApi();
