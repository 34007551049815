import React, { FunctionComponent } from "react";
import CheckboxComponent, { CheckboxProps } from "@mui/material/Checkbox";
import {
  PropsWithAriaLabel,
  RequireAriaLabelOrAriaLabelledBy,
} from "components/types";
import palette from "styles/mui/palette";

// The "out of the box" MUI checkbox component allows anonymous checkboxes to implemented
// these types insure that the checkbox will have an accessible name by supporting either:
// a name prop
// or an aria-label prop
// or an inputProp with either:
//    an aria-label property
//    or an aria-labelledby property

interface CheckboxWithName {
  name: string;
}

interface CheckboxWithAccessibleInputProps {
  inputProps: RequireAriaLabelOrAriaLabelledBy;
}

type AccessibleProps =
  | CheckboxWithName
  | PropsWithAriaLabel
  | CheckboxWithAccessibleInputProps;

const Checkbox: FunctionComponent<CheckboxProps & AccessibleProps> = ({
  // need to remove styling so no accidental color changes are made
  sx,
  ...props
}) => {
  // if for some reason a name and an aria-label are supplied by the developer calling Checkbox,
  // we will assume that the aria-label is what they would like exposed to the a11y tree
  return (
    <CheckboxComponent
      sx={
        {
          "&.MuiCheckbox-root.Mui-checked": {
            color: `${palette.blue} !important`, // Enforce checked color
          },
          ...sx,
        }
        //
      }
      inputProps={{ "aria-label": props["aria-label"] || props.name }}
      {...props}
    />
  );
};

export default Checkbox;
