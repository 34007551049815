import { useInfiniteQuery } from "@tanstack/react-query";
import reduce from "lodash/reduce";
import { useEffect } from "react";
import { selectLoggedInUser } from "shared/features/user/selectors";
import { GetUserPushNotificationsStateEnum, fetchUserPushNotificationsApi } from "shared/fetch/src/apis/UserPushNotificationsApi";

let fetching = false;

export const userNotificationQueryKey = ({
  id,
  state,
}: {
  id: string;
  state?: GetUserPushNotificationsStateEnum;
}) => ["userNotifications", "user", id, "state", state];

const useGetNotifications = (state?: GetUserPushNotificationsStateEnum) => {
  const user = selectLoggedInUser();
  const currentUserId = user?.id?.toString() || "";

  const {
    data,
    isLoading,
    fetchNextPage: fetchNextNotificationsPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    userNotificationQueryKey({ id: currentUserId, state }),
    ({ pageParam = 1 }) => {
      return fetchUserPushNotificationsApi.getUserPushNotifications({
        id: currentUserId,
        state,
        page: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.page === lastPage.last) {
          return false;
        }

        return (lastPage?.page || 0) + 1;
      },
      keepPreviousData: true,
      enabled: !!currentUserId,
    }
  );

  const fetchNextPage = () => {
    if (!fetching && hasNextPage) {
      fetching = true;
      fetchNextNotificationsPage();
    }
  };

  const notifications = reduce(
    data?.pages,
    (acc: any, { results: fetchedNotifications = [] }) => {
      return [...acc, ...fetchedNotifications];
    },
    []
  );

  const counts = data ? data?.pages[0]?.counts : { sent: 0, read: 0 };

  useEffect(() => {
    fetching = false;
  }, [notifications.length]);

  return {
    notifications,
    counts,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  };
};

export default useGetNotifications;
