import { useQuery } from "@tanstack/react-query";
import { fetchLanguageCodesApi } from "shared/fetch/src/apis/LanguageCodesApi";
import { LanguageCodeOutputSchemaResults } from "shared/fetch/src/models/LanguageCodeOutputSchemaResults";

const useGetLanguagesList = () => {
  const { data, isLoading } = useQuery(["getLanguages"], () =>
    fetchLanguageCodesApi.getLanguages()
  );

  const results = data?.results?.map(
    (lang: LanguageCodeOutputSchemaResults) => {
      const tempLang = { ...lang };

      if (lang.language?.includes(";")) {
        tempLang.language = lang.language.split("; ").join("/");
      }
      return tempLang;
    }
  );

  return {
    languagesList: results,
    isPending: isLoading,
  };
};

export default useGetLanguagesList;
