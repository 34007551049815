import React, { useState, useEffect } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import CancelIcon from "@mui/icons-material/Cancel";
import isEmpty from "lodash/isEmpty";
import Autocomplete from "components/Autocomplete";
import SearchInput from "components/SearchField/SearchInput";
import { useGetOptions } from "./utils";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import Paper from "components/Paper";
import NewAccountButton from "components/NewAccountButton";
import theme from "../../styles/mui";
import { selectFeatures } from "shared/features/featureFlags/selectors";

interface ISearchFieldProps {
  "data-testid"?: string;
  ariaLabel?: string;
  disableAdd?: boolean;
  additionalCheck?: boolean; // used for any additional checks
  ignoreValue?: string; // this can be used to override default behavior when selecting a value
  searchInputAppearance?: "" | "add";
  placeholder?: string;
  renderOption?: any;
  defaultValue?: string;
  defaultOptions?: any;
  onChange?: any;
  loadOptions?: any;
  clearInputOnChange?: boolean;
  clearInputOnAdd?: boolean;
  disableClearableProp?: boolean;
  resetValue?: boolean;
  onAddClick?: () => void;
  onValueChange?: (value: string) => void;
  id?: string;
  onFocus?: (cb: any) => void;
  onClear?: () => void;
  autoWidth?: boolean;
  hasFooter?: boolean;
  scheduledMembers?: number[];
  isSlotActionDrawer?: boolean;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      "& .MuiAutocomplete-clearIndicator": {
        marginRight: 8,
        backgroundColor: "transparent",
      },
    },
    clearIndicatorDirty: {
      visibility: "visible",
      padding: 0,
      marginTop: 3,
    },
  })
);

const renderOptionWithHighlight = (
  props: any,
  option: any,
  { inputValue }: any
): any => {
  const matches = match(option, inputValue);
  const parts = parse(option, matches);
  return (
    <li {...props} key={option.id}>
      {(parts || []).map((part: any, index: any) => (
        <span
          key={index}
          style={{ fontWeight: part?.highlight ? "bold" : "normal" }}
        >
          {part?.text}
        </span>
      ))}
    </li>
  );
};

const SearchField = ({
  "data-testid": testId,
  defaultValue,
  onChange: onChangeProp,
  additionalCheck,
  clearInputOnChange,
  onAddClick,
  clearInputOnAdd,
  disableClearableProp = false,
  resetValue = false,
  ignoreValue,
  onValueChange,
  loadOptions,
  renderOption: renderOptionProp,
  onFocus,
  id,
  disableAdd,
  placeholder,
  searchInputAppearance,
  onClear,
  defaultOptions,
  autoWidth = false,
  hasFooter = false,
  ariaLabel,
  scheduledMembers,
  isSlotActionDrawer,
}: ISearchFieldProps) => {
  const [inputValue, setInputValue] = useState(defaultValue || "");
  const [value, setValue] = useState("");
  // state to use when you want to force the page to re-render
  const [counter, setCounter] = useState(0);
  const classes = useStyles();
  const { palette } = theme;
  const features = selectFeatures();

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  const onItemSelected = (item: any, reason: string) => {
    if (onChangeProp) {
      onChangeProp(item, reason);

      /**
       * force page to re-render since the value never changes when adding ad-hoc questions
       * and always remains as `Add a custom question to set` unlike when adding common
       * questions where value changes
       */
      const { item: tempItem } = item;
      if (additionalCheck && !tempItem?.common) {
        /**
         * Clear inputValue  here since adding clearInputOnChange prop
         * will also clear when adding common questions which is not
         * recommended.
         *
         * Clearing inputValue is required in the cms workflow when adding
         * ad-hoc question but not needed when adding common questions where
         * clearInputOnAdd is needed
         */
        setInputValue("");
        resetValue && setValue(item?.value);
        setOptions({
          labelKey: "x",
          items: [],
        });
        handleAddCounter();
      }
    }

    if (clearInputOnChange) {
      setInputValue("");
      resetValue && setValue("");
      setOptions({ labelKey: "x", items: [] });
    }
  };

  const handleAddCounter = () => {
    setCounter(counter + 1);
  };

  const handleAdd = () => {
    // @ts-ignore: Object is possibly 'null'.
    onAddClick();
    if (clearInputOnAdd) {
      setInputValue("");
      setOptions({ labelKey: "x", items: [] });
    }
    handleAddCounter();
  };
  const { getValueFromText, options, setOptions, onInputChange, onChange } =
    useGetOptions({
      onItemSelected,
      onItemInputChange: (itemValue: any, reason: any) => {
        if (itemValue === ignoreValue) {
          return null as any;
        }

        setInputValue(itemValue);
        resetValue && setValue("");
        onValueChange && onValueChange(itemValue);
        if ((reason === "input" || reason === "clear") && loadOptions) {
          loadOptions(itemValue, setOptions, defaultOptions, scheduledMembers);
        }
      },
    });

  const renderOption = (
    props: any,
    option: any,
    { inputValue: itemValue }: any
  ): any => {
    if (renderOptionProp) {
      return renderOptionProp(props, option, {
        inputValue: itemValue,
        item: getValueFromText(option),
      });
    } else {
      return renderOptionWithHighlight(props, option, {
        inputValue: itemValue,
      });
    }
  };

  const PaperContainer = (containerProps: any) => (
    <Paper
      elevation={options.length ? 1 : 0}
      style={autoWidth ? { width: "fit-content" } : {}}
    >
      {containerProps.children}
      {hasFooter && !isEmpty(options) && (
        <div
          style={{
            borderTop: `1px solid ${palette?.borderGray}`,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <NewAccountButton color="link-secondary" linkBtn />
        </div>
      )}
    </Paper>
  );

  const minorMembersEffect = () => {
    // we have to do some dom-digging with JS html methods to get at a few
    // elements we cannot access via Material UI Autocomplete props

    // first find the wrapper element for all the results
    const searchResultsWrapper = document.getElementById(
      "member-search-field-popup"
    );
    // find all elements of minor members in the search results by
    // selecting id's starting with this string:
    const minors = searchResultsWrapper?.querySelectorAll(
      '[id^="minor-result-for-member-quick-search-"]'
    );
    // now we have a node list of the minors--since we have access to these unique id's we
    // could set the styling in our css stylesheet, but we need to set the styling on the parent <li>
    // element. We could do this in css with the :has() selector, but Firefox doesn't support it yet
    // https://developer.mozilla.org/en-US/docs/Web/CSS/:has#browser_compatibility
    minors?.forEach((innerDivElement) => {
      const nonInteractiveStyle = "cursor:default; pointer-events:none;";
      const listElement = innerDivElement?.parentElement;
      listElement?.setAttribute("style", nonInteractiveStyle);
    });
  };

  useEffect(() => {
    if (options.length && !features.hasCtmMinorAccess()) {
      minorMembersEffect();
    }
  }, [options]);

  return (
    <Autocomplete
      onFocus={() => onFocus?.(setOptions)}
      key={counter}
      id={id}
      classes={classes}
      freeSolo
      value={value}
      disableClearable={disableClearableProp && !inputValue}
      clearIcon={<CancelIcon onClick={onClear} />}
      inputValue={inputValue}
      options={options}
      filterOptions={(x) => x}
      PaperComponent={PaperContainer}
      onInputChange={onInputChange}
      onChange={onChange}
      renderOption={(props, option: any, { inputValue: itemValue }: any) =>
        renderOption(props, option, { inputValue: itemValue })
      }
      renderInput={(params) => (
        <SearchInput
          disableAdd={disableAdd}
          placeholder={placeholder}
          appearance={searchInputAppearance}
          renderInputParams={params}
          onAddClick={handleAdd}
          testId={testId}
          ariaLabel={ariaLabel}
          isSlotActionDrawer={isSlotActionDrawer}
        />
      )}
    />
  );
};

export default SearchField;
