import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";
import { fetchHeadsUpApi } from "shared/fetch/src/apis/HeadsUpApi";

interface IProps {
  userId: string | undefined;
  isSelectedAll?: boolean;
}

export default ({ userId, isSelectedAll = false }: IProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const successMessage = isSelectedAll
    ? "Success! All Heads Up notifications on the current page have been deleted."
    : "Success! All selected notifications have been deleted.";

  const { isSuccess: hasFinishedDeleting, mutate: deleteHeadsUpNotifications } =
    useMutation(
      (notificationIds: string) => {
        return fetchHeadsUpApi.clearHeadsUpNotification({ notificationIds });
      },
      {
        onSuccess: () => {
          if (userId) {
            queryClient.invalidateQueries({
              queryKey: ["headsUpNotifications", userId],
            });
          }
          dispatch(showSnackbar(successMessage, "success", 445));
        },
        onError: () => {
          dispatch(
            showSnackbar(
              "There was a problem deleting notifications.",
              "danger"
            )
          );
        },
      }
    );

  return {
    hasFinishedDeleting,
    deleteHeadsUpNotifications,
  };
};
