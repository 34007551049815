import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { useShowStatusSnackbar } from "shared/state/ui/snackbar";
import {
  DeleteAppointmentNoteRequest,
  fetchAppointmentNotesApi,
} from "shared/fetch/src/apis/AppointmentNotesApi";
import { getFetchSlotQueryKey } from "../xocal/useGetSlot";
import { getFetchSlotsQueryKey } from "../xocal/useGetSlots";

type DeleteNoteMutationFn = (
  requestParameters: DeleteAppointmentNoteRequest
) => Promise<void>;

const useDeleteVisitNote = (
  config?: UseMutationOptions<void, Error, DeleteAppointmentNoteRequest>
): UseMutationResult<void, Error, DeleteAppointmentNoteRequest> => {
  const { showSuccessStatusSnackbar, showErrorStatusSnackbar } =
    useShowStatusSnackbar();
  const queryClient = useQueryClient();

  const mutationFn: DeleteNoteMutationFn = (
    requestParameters: DeleteAppointmentNoteRequest
  ) => {
    return fetchAppointmentNotesApi.deleteAppointmentNote(
      requestParameters
    ) as Promise<void>;
  };

  const defaultConfig: UseMutationOptions<
    void,
    Error,
    DeleteAppointmentNoteRequest
  > = {
    onSuccess: () => {
      showSuccessStatusSnackbar("Success! The visit note has been deleted.");
      queryClient.invalidateQueries(getFetchSlotQueryKey({}));
      queryClient.invalidateQueries(getFetchSlotsQueryKey({}));
    },
    onError: () => {
      showErrorStatusSnackbar("Failed to delete the note.", "danger");
    },
    ...config,
  };

  return useMutation<void, Error, DeleteAppointmentNoteRequest>(
    mutationFn,
    defaultConfig
  );
};

export default useDeleteVisitNote;
