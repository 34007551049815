import { useQuery } from "@tanstack/react-query";
import {
  FetchMemberAppointmentTypesRequest,
  fetchMemberAppointmentsApi,
} from "shared/fetch/src/apis/MemberAppointmentsApi";

const fetchAppointmentsQueryKey = (
  request: FetchMemberAppointmentTypesRequest
) => ["appointmentTypes", request.id, request.clinicId, request.serviceLineId];

const useGetMemberAppointmentTypes = (
  request: FetchMemberAppointmentTypesRequest
) => {
  const { data: appointmentTypes, isInitialLoading } = useQuery(
    fetchAppointmentsQueryKey(request),
    () => {
      return fetchMemberAppointmentsApi.fetchMemberAppointmentTypes(request);
    },
    {
      enabled: !!request.id && !!request.serviceLineId,
    }
  );
  return { appointmentTypes: appointmentTypes?.results, isLoading: isInitialLoading };
};

export default useGetMemberAppointmentTypes;
