import * as React from "react";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  StoreEnhancer,
  Store,
} from "redux";
import merge from "lodash/merge";

import {
  entitiesReducer,
  queriesReducer,
  queryMiddleware,
  errorsReducer,
} from "redux-query";
import { Provider as ReduxQueryProvider } from "redux-query-react";
import { stubRequest } from "shared/middlewares/stub-requests";
import networkInterface from "shared/middlewares/networkInterface";
import { reducer as auth, getInitialAuthState } from "shared/features/auth";
import { reducer as ui, getInitialUIState } from "shared/state/ui";
import {
  featureFlagsReducer,
  getInitialFeatureFlagState,
  setLoggedInUserFeatureFlags,
} from "./featureFlags";
import { nativeApp, getInitialNativeAppState } from "shared/state/nativeApp";
import authHeaderMiddleware from "shared/features/auth/authHeaderMiddleware";

import { State } from "shared/types";
import resolveActionableItemsMiddleware from "shared/features/actionableItems/resolveActionableItemsMiddleware";
import { WorkspaceDraftDataSchemaItem } from "shared/api/src/models/WorkspaceDraftDataSchemaItem";
import patientAccessMiddleware from "shared/features/auth/patientAccessMiddleware";
import { devMiddleware } from "shared/middlewares/dev-middleware";
import memberAccessMiddleware from "shared/features/auth/memberAccessMiddleware";
import { reducer as workspace, initialWorkspaceState } from "./workspaceDraft";
import { RollbarProvider } from "containers/RollBarProvider";
import { reducer as calculator, initialCalculatorState } from "./calculator";

// Reducers
const rootReducer = combineReducers({
  errors: errorsReducer,
  // redux-query
  entities: entitiesReducer,
  queries: queriesReducer,
  auth,
  ui,
  nativeApp,
  featureFlags: featureFlagsReducer,
  workspace,
  calculator,
});

// Initial state - keep it simple, just to prevent basic null selection errs
export const initialState: State = {
  entities: {
    draft: {
      data: [] as WorkspaceDraftDataSchemaItem[],
      id: null,
    } as any,
  },
  queries: {},
  auth: getInitialAuthState(() => store),
  ui: getInitialUIState(),
  nativeApp: getInitialNativeAppState(),
  errors: {},
  featureFlags: getInitialFeatureFlagState(),
  workspace: initialWorkspaceState(),
  calculator: initialCalculatorState(),
};

// Middleware / redux query setup
const getQueries = (state: State) => state.queries;
const getEntities = (state: State) => state.entities;

// Middlewares must be destructured into enhancers which only enable devtools
// if they are present - prevents "undefined" middlewares from being applied
// See this issue comment by Dan Abramov:
// https://github.com/facebook/create-react-app/issues/1114#issuecomment-263650957
const middleware = [
  stubRequest,
  authHeaderMiddleware,
  patientAccessMiddleware,
  memberAccessMiddleware,
  queryMiddleware(networkInterface, getQueries, getEntities),
  resolveActionableItemsMiddleware,
  devMiddleware,
];

const enhancers: StoreEnhancer<{ dispatch: {} }, {}>[] = [
  applyMiddleware(...middleware),
];
if (window && (window as any)?.__REDUX_DEVTOOLS_EXTENSION__) {
  // run redux devtools with trace mode enabled in production for better debugging capability
  const isDev =
    typeof process !== "undefined" && process.env.NODE_ENV === "development";
  const isCypress = (window as any)?.Cypress;
  const reduxDevtoolsEnhancer =
    isDev && !isCypress
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION__({
          trace: true,
          traceLimit: 25,
        })
      : (window as any).__REDUX_DEVTOOLS_EXTENSION__();
  enhancers.push(reduxDevtoolsEnhancer);
}
export const store: Store = createStore(
  rootReducer,
  initialState as any,
  compose(...enhancers)
);

if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
  import("shared/stubs/msw").then((msw) => {
    msw.startMockServiceWorker();
  });
}

const NON_RETRY_STATUS_CODES = [401, 403, 404];

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, error: any) =>
        !(count > 3 || NON_RETRY_STATUS_CODES.includes(error?.status)),
    },
  },
});

// Import
const StateManagement = (props: any) => (
  <Provider store={props.store || store}>
    <RollbarProvider>
      <ReduxQueryProvider queriesSelector={getQueries}>
        <QueryClientProvider client={queryClient}>
          {props.children}
        </QueryClientProvider>
      </ReduxQueryProvider>
    </RollbarProvider>
  </Provider>
);

export const testQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

export const TestStateManagement = (props: any) => {
  const testStore =
    props.store ||
    createStore(
      rootReducer,
      merge({}, initialState, props.initialState),
      compose(...enhancers)
    );

  if (props?.featureFlags) {
    testStore.dispatch(setLoggedInUserFeatureFlags(props.featureFlags));
  }

  return (
    <Provider store={testStore}>
      <RollbarProvider>
        <ReduxQueryProvider queriesSelector={getQueries}>
          <QueryClientProvider client={testQueryClient}>
            {props.children}
          </QueryClientProvider>
        </ReduxQueryProvider>
      </RollbarProvider>
    </Provider>
  );
};

export default StateManagement;
