// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';
import {
    EpisodeQueueSummaryLastCtmUpdater,
    EpisodeQueueSummaryLastCtmUpdaterFromJSON,
    EpisodeQueueSummaryLastCtmUpdaterToJSON,
} from './EpisodeQueueSummaryLastCtmUpdater';
import {
    EpisodeQueueSummaryOwner,
    EpisodeQueueSummaryOwnerFromJSON,
    EpisodeQueueSummaryOwnerToJSON,
} from './EpisodeQueueSummaryOwner';
import {
    QueueMemberInfo,
    QueueMemberInfoFromJSON,
    QueueMemberInfoToJSON,
} from './QueueMemberInfo';
import {
    RoleOutputCoreSchema,
    RoleOutputCoreSchemaFromJSON,
    RoleOutputCoreSchemaToJSON,
} from './RoleOutputCoreSchema';


/**
 * 
 * @export
 * @interface EpisodeQueueSummary
 */
export interface EpisodeQueueSummary  {
    /**
     * 
     * @type {string}
     * @memberof EpisodeQueueSummary
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EpisodeQueueSummary
     */
    state: EpisodeQueueSummaryStateEnum;
    /**
     * 
     * @type {string}
     * @memberof EpisodeQueueSummary
     */
    category: EpisodeQueueSummaryCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof EpisodeQueueSummary
     */
    serviceLine: string;
    /**
     * 
     * @type {QueueMemberInfo}
     * @memberof EpisodeQueueSummary
     */
    memberInfo: QueueMemberInfo;
    /**
     * 
     * @type {Date}
     * @memberof EpisodeQueueSummary
     */
    addedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EpisodeQueueSummary
     */
    archivedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EpisodeQueueSummary
     */
    needsActionSince?: Date;
    /**
     * 
     * @type {number}
     * @memberof EpisodeQueueSummary
     */
    totalMessages?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EpisodeQueueSummary
     */
    isVisit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EpisodeQueueSummary
     */
    timeSensitive?: boolean;
    /**
     * 
     * @type {EpisodeQueueSummaryOwner}
     * @memberof EpisodeQueueSummary
     */
    owner?: EpisodeQueueSummaryOwner;
    /**
     * 
     * @type {Array<RoleOutputCoreSchema>}
     * @memberof EpisodeQueueSummary
     */
    roles?: Array<RoleOutputCoreSchema>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EpisodeQueueSummary
     */
    badges?: Array<string>;
    /**
     * 
     * @type {Array<ActionableItemSummary>}
     * @memberof EpisodeQueueSummary
     */
    actionableItems?: Array<ActionableItemSummary>;
    /**
     * 
     * @type {boolean}
     * @memberof EpisodeQueueSummary
     */
    cctAssigned?: boolean;
    /**
     * 
     * @type {EpisodeQueueSummaryLastCtmUpdater}
     * @memberof EpisodeQueueSummary
     */
    lastCtmUpdater?: EpisodeQueueSummaryLastCtmUpdater;
    /**
     * 
     * @type {boolean}
     * @memberof EpisodeQueueSummary
     */
    virtualUrgentCare?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EpisodeQueueSummary
     */
    travelState?: string;
}

export function EpisodeQueueSummaryFromJSON(json: any): EpisodeQueueSummary {
    return {
        'id': json['id'],
        'state': json['state'],
        'category': json['category'],
        'serviceLine': json['service_line'],
        'memberInfo': QueueMemberInfoFromJSON(json['member_info']),
        'addedAt': !exists(json, 'added_at') ? undefined : new Date(json['added_at']),
        'archivedAt': !exists(json, 'archived_at') ? undefined : new Date(json['archived_at']),
        'needsActionSince': !exists(json, 'needs_action_since') ? undefined : new Date(json['needs_action_since']),
        'totalMessages': !exists(json, 'total_messages') ? undefined : json['total_messages'],
        'isVisit': !exists(json, 'is_visit') ? undefined : json['is_visit'],
        'timeSensitive': !exists(json, 'time_sensitive') ? undefined : json['time_sensitive'],
        'owner': !exists(json, 'owner') ? undefined : EpisodeQueueSummaryOwnerFromJSON(json['owner']),
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] as Array<any>).map(RoleOutputCoreSchemaFromJSON),
        'badges': !exists(json, 'badges') ? undefined : json['badges'],
        'actionableItems': !exists(json, 'actionable_items') ? undefined : (json['actionable_items'] as Array<any>).map(ActionableItemSummaryFromJSON),
        'cctAssigned': !exists(json, 'cct_assigned') ? undefined : json['cct_assigned'],
        'lastCtmUpdater': !exists(json, 'last_ctm_updater') ? undefined : EpisodeQueueSummaryLastCtmUpdaterFromJSON(json['last_ctm_updater']),
        'virtualUrgentCare': !exists(json, 'virtual_urgent_care') ? undefined : json['virtual_urgent_care'],
        'travelState': !exists(json, 'travel_state') ? undefined : json['travel_state'],
    };
}

export function EpisodeQueueSummaryToJSON(value?: EpisodeQueueSummary): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'state': value.state,
        'category': value.category,
        'service_line': value.serviceLine,
        'member_info': QueueMemberInfoToJSON(value.memberInfo),
        'added_at': value.addedAt === undefined ? undefined : value.addedAt.toISOString(),
        'archived_at': value.archivedAt === undefined ? undefined : value.archivedAt.toISOString(),
        'needs_action_since': value.needsActionSince === undefined ? undefined : value.needsActionSince.toISOString(),
        'total_messages': value.totalMessages,
        'is_visit': value.isVisit,
        'time_sensitive': value.timeSensitive,
        'owner': EpisodeQueueSummaryOwnerToJSON(value.owner),
        'roles': value.roles === undefined ? undefined : (value.roles as Array<any>).map(RoleOutputCoreSchemaToJSON),
        'badges': value.badges,
        'actionable_items': value.actionableItems === undefined ? undefined : (value.actionableItems as Array<any>).map(ActionableItemSummaryToJSON),
        'cct_assigned': value.cctAssigned,
        'last_ctm_updater': EpisodeQueueSummaryLastCtmUpdaterToJSON(value.lastCtmUpdater),
        'virtual_urgent_care': value.virtualUrgentCare,
        'travel_state': value.travelState,
    };
}

/**
* @export
* @enum {string}
*/
export enum EpisodeQueueSummaryStateEnum {
    Unstarted = 'unstarted',
    Draft = 'draft',
    Active = 'active',
    Archived = 'archived',
    Deleted = 'deleted',
    Suspended = 'suspended'
}
/**
* @export
* @enum {string}
*/
export enum EpisodeQueueSummaryCategoryEnum {
    Clinical = 'clinical',
    Preventive = 'preventive',
    Messaging = 'messaging'
}


