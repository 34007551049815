import { useQuery } from "@tanstack/react-query";
import {
  MemberAppointmentListSummariesDispositionsEnum,
  MemberAppointmentListSummariesStatusEnum,
  fetchMemberAppointmentsApi,
} from "shared/fetch/src/apis/MemberAppointmentsApi";

const visitsQueryKey = (
  id?: string | number,
  page?: string | number,
  pageSize?: string | number,
  status?: string,
  relevant?: boolean,
  dispositions?: MemberAppointmentListSummariesDispositionsEnum[]
) => [
  "visits",
  id?.toString(),
  {
    page: page?.toString(),
    pageSize: pageSize?.toString(),
    status: status?.toString(),
    relevant,
    dispositions,
  },
];

interface FetchVisitsOptions {
  pageSize?: string | number;
  page?: string | number;
  status?: MemberAppointmentListSummariesStatusEnum;
  relevant?: boolean;
  dispositions?: MemberAppointmentListSummariesDispositionsEnum[];
}
const useFetchVisits = (
  id: string | number,
  { pageSize, page, status, relevant, dispositions }: FetchVisitsOptions = {}
) => {
  const { data, isInitialLoading } = useQuery(
    visitsQueryKey(id, page, pageSize, status, relevant, dispositions),
    () =>
      fetchMemberAppointmentsApi.memberAppointmentListSummaries({
        id: id?.toString(),
        pageSize: pageSize?.toString(),
        page: page?.toString(),
        status,
        relevant: !!relevant,
        dispositions,
      }),
    {
      keepPreviousData: true,
      enabled: !!id,
    }
  );

  return {
    visits: data?.results || [],
    visitCount: data?.count,
    isLoading: isInitialLoading,
    paginationMetadata: {
      page: data?.page || 0,
      count: data?.last || 0,
    },
  };
};

export default useFetchVisits;
